export const models = {
  actions: {

    fetchCollection({ dispatch }, collection) {
      // console.log('fetchCollection', collection)
      // Clear any existing models
      collection.clearModels()
      // Fetch the collection
      return collection.fetch().catch(
        // Error Handling
        (error) => {
          dispatch('processError', error)
          throw error
        }
      )
    },

    fetchModel({ dispatch }, model) {
      // console.log('fetchModel', model)
      return model.fetch().catch(
        // Error Handling
        (error) => {
          dispatch('processError', error, { root: true })
          throw error
        }
      )
    },

    saveModel({ dispatch }, model) {
      // console.log('saveModel', model)
      return model.save().catch(
        // Error Handling
        (error) => {
          dispatch('processError', error, { root: true })
          throw error
        }
      )
    },

    deleteModel({ dispatch }, model) {
      // console.log('deleteModel', model)
      return model.delete().catch(
        // Error Handling
        (error) => {
          dispatch('processError', error, { root: true })
          throw error
        }
      )
    }
  },
}
