// ---------------------
// Section List Model ==
// ---------------------

import BCollection from './base/BCollection'
import Section from './Section'

export default class SectionList extends BCollection {

  bOptions() {
    return {
      model: Section,
      remoteModel: 'sections'
    }
  }

  routes() {
    return {
      fetch: '/api/sections/?l={listing}&q={query}&p={current_page}&r={current_rows}'
    }
  }

  defaults() {
    return this.paginationFields
  }
}