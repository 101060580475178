<template>
  <v-sheet
    class="jexy-listing"
    :class="{ 'listing-scrollbar': listing.length > 0}"
  >
    <!-- Heading -->
    <j-heading
      v-if="heading"
      :heading="heading"
      :subhead="subhead"
      :count="showCount && listing.length"
    >
      <template #actions>
        <slot name="actions" />
      </template>
    </j-heading>

    <v-divider />

    <!-- Listing -->
    <v-list
      v-if="listing.length || loading"
      three-line
      class="py-0"
    >
      <v-list-item-group
        v-model="selected"
        active-class="gray--text"
      >
        <template v-for="(entry, index) in listing.getModels()">
          <!-- Date Header -->
          <v-subheader
            v-if="showSubhead(index)"
            :key="`header-${entry.tag19}`"
            class="listing-date-header"
          >
            {{ entry.published_at | weekdayDate }}
          </v-subheader>

          <!-- List Item -->
          <v-list-item
            :key="`item-${entry.tag19}`"
            class="mb-0"
            :class="{ featured: entry.featured }"
            @click="handleSelection(index)"
          >
            <!-- Badge -->
            <v-list-item-avatar
              v-if="showAvatar && entry.badge_src"
              tile
            >
              <j-graphic
                :src="entry.badge_src"
                aspect-ratio="1"
              />
            </v-list-item-avatar>

            <!-- Text -->
            <v-list-item-content>
              <!-- Section -->
              <v-list-item-subtitle
                class="listing-kicker"
                :style="`color: ${entry.section.highlight_color}`"
              >
                {{ entry.section.name }}
                <j-counter :count="entry.comment_count" />
              </v-list-item-subtitle>

              <!-- Title -->
              <v-list-item-title>
                <j-icon
                  v-if="entry.requested"
                  icon="star"
                  class="mr-1"
                />
                <j-label
                  :title="entry.releaseDate"
                  color="primary"
                  small
                  class="mr-1 mt-n1"
                />
                <span
                  class="listing-headline"
                  v-html="entry.$.title"
                />
              </v-list-item-title>

              <!-- Description -->
              <v-list-item-subtitle
                class="listing-description"
              >
                <j-markdown
                  v-if="entry.$.description"
                  :source="entry.$.description"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider :key="`divider-${entry.tag19}`" />
        </template>
      </v-list-item-group>
    </v-list>

    <!-- No listing -->
    <p v-else>
      Nothing to show.
    </p>

    <!-- Footer -->
    <div
      v-if="$slots.footer"
      class="listing-footer pa-3"
    >
      <slot name="footer" />
    </div>
  </v-sheet>
</template>

<script>

// Helpers
import moment from 'moment'

export default {
  name: 'ItemListing',
  // -------------
  // Properties ==
  // -------------
  props: {
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    listing: {
      type: Object,
      default: null
    },
    pagination: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: [Number, Boolean],
      default: false
    },
    showAvatar: {
      type: Boolean,
      default: false
    }
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      selected: null
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // TODO - use watcher on selected
    handleSelection(index) {
      this.selected = index
      this.listing.set('selected', index)
      this.$emit('selected', index)
    },
    showSubhead(index) {
      // Always show the first header
      if (!index) {
        return true
      }
      // Get the current subhead
      const currentEntry = this.listing.models[index]
      const currentSubhead = moment(currentEntry.published_at).format('D MMM YYYY')
      // Get the previous subhead
      const previousEntry = this.listing.models[index-1]
      const previousSubhead = moment(previousEntry.published_at).format('D MMM YYYY')
      // Show subhead if it has changed
      return currentSubhead != previousSubhead
    }
  }
}
</script>

<style lang="scss">

.jexy-listing {

  .listing-date-header {
    @extend %j-background-light;
    font-weight: bold !important;
  }

  .listing-kicker {
    @extend %j-kicker;
  }

  .listing-headline {
    @extend %j-heading-3;
  }

  .listing-description {
    @extend %j-body;
    margin: 0;
  }

  .listing-footer {
    @extend %j-background-light;
  }

}
</style>
