<template>
  <form
    class="j-form"
    @keydown="checkFormSubmission"
  >
    <Loader
      v-if="!hideLoader"
      :is-loading="modelObj.loading || modelObj.saving || modelObj.deleting"
    />

    <!-- Validation Error -->
    <j-alert
      v-if="showError"
      :message="errorMessage"
      type="error"
    />

    <!-- Form Row -->
    <v-row>
      <slot />
    </v-row>
  </form>
</template>

<script>

import Loader from '@/components/elements/Loader'

import { get } from 'lodash'

export default {
  components: {
    Loader
  },
  props: {
    model: {
      type: [String, Object],
      required: true
    },
    hideLoader: {
      type: Boolean,
      default: false
    },
    hideErrors: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      modelRef: this.model
    }
  },
  computed: {
    modelObj () {
      // Get model
      let model = {}
      // console.log(this.model, typeof this.model)
      if (typeof this.model == 'string') {
        if (this.model.endsWith(']')) {
          const matched = this.model.match(/^(.*)\[(.*)\]$/)
          const listingName = matched[1]
          const tag19 = matched[2]
          const listing = get(this.$store.state, listingName)
          model = listing.find({ tag19 })
        } else {
          model = get(this.$store.state, this.model)
        }
      } else {
        model = this.model
      }
      return model
    },
    showError() {
      return !this.modelObj.isValid && !this.hideErrors
    },
    errorMessage () {
      let errorMessage = 'Missing or invalid information!'
      if (this.modelObj.errors.base) {
        errorMessage = this.modelObj.errors.base.join('<br/>')
      }
      return errorMessage
    }
  },
  methods: {
    checkFormSubmission (event) {
      if (event.key === 'Enter') {
        this.$emit('submit')
      }
    }
  }
}
</script>

<style lang="scss">

.j-form {
  width: 100%;
}

</style>
