import Vue from 'vue'
import Router from 'vue-router'
import Meta from "vue-meta";
import guards from '@/guards'
import store from '@/store'

Vue.use(Router)
Vue.use(Meta)

let router = new Router({
  // base: 'kexy',
  mode: "history",
  linkActiveClass: "active",
  routes: [
    // -------
    // Auth ==
    // -------
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
    },{
      path: "/forgot",
      name: "forgot-password",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
    },{
      path: "/reset/:token",
      name: "password-reset",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
    },{
      path: "/",
      component: () => import(/* webpackChunkName: "jexy" */ "@/components/layout/Jexy.vue"),
      ...guards.authenticated,
      children: [
        // -------------------
        // Home / Dashboard ==
        // -------------------
        {
          path: "",
          name: "home",
          component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        },{
          path: "/dashboard",
          name: "dashboard",
          component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        },
        // --------
        // Alias ==
        // --------
        {
          path: "/alias/:slug",
          name: "alias",
          component: () => import(/* webpackChunkName: "auth" */ "@/views/Auth.vue"),
        },
        // ----------------
        // Announcements <|
        // ----------------
        {
          path: "/announcements",
          component: () => import(/* webpackChunkName: "generic" */ "@/views/Generic.vue"),
          children: [
            {
              path: "",
              name: "announcements-index",
              component: () => import(/* webpackChunkName: "announcements-index" */ "@/views/Announcements/Announcements.vue")
            },{
              path: "create",
              name: "announcements-create",
              props: () => ({ mode: 'create' }),
              component: () => import(/* webpackChunkName: "announcements-create" */ "@/views/Announcements/Announcement.vue")
            },{
              path: ":tag19",
              props: (route) => ({ tag19: route.params.tag19 }),
              component: () => import(/* webpackChunkName: "announcements-record" */ "@/views/Announcements/Announcement.vue"),
              children: [
                {
                  path: "",
                  name: "announcements-view",
                  component: () => import(/* webpackChunkName: "announcements-view" */ "@/views/Announcements/AnnouncementPanels/AnnouncementView.vue"),   
                }, {
                  path: "edit",
                  name: "announcements-edit",
                  component: () => import(/* webpackChunkName: "announcements-edit" */ "@/views/Announcements/AnnouncementPanels/AnnouncementEdit.vue"),
                }
              ]
            }
          ],
        },
        // -----------
        // Articles <|
        // -----------
        {
          path: "/articles",
          component: () => import(/* webpackChunkName: "generic" */ "@/views/Generic.vue"),
          children: [
            {
              // Directory
              path: "",
              name: "articles-index",
              component: () => import(/* webpackChunkName: "articles-index" */ "@/views/Articles/ArticlesIndex.vue"),
            },{
              // Search
              path: "search",
              name: "articles-search",
              component: () => import(/* webpackChunkName: "articles-search" */ "@/views/Articles/SearchArticles.vue"),
            },{
              // Schedule
              path: "schedule",
              name: "articles-schedule",
              component: () => import(/* webpackChunkName: "articles-schedule" */ "@/views/Articles/Schedule.vue"),
            },{
              // View
              path: ":tag19",
              name: "articles-view",
              props: (route) => ({ tag19: route.params.tag19 }),
              component: () => import(/* webpackChunkName: "articles-view" */ "@/views/Articles/Article.vue"),
            }
          ]
        },
        // --------------
        // Assignments <|
        // --------------
        {
          path: "/assignments",
          name: "assignments",
          component: () => import(/* webpackChunkName: "assignments" */ "@/views/Opps/Assignments.vue")
        },
        // ---------
        // Drafts <|
        // ---------
        {
          path: "draft",
          component: () => import(/* webpackChunkName: "draft-index" */ "@/views/Drafts/Draft.vue"),
          children: [
            {
              path: "",
              name: "draft-create",
              component: () => import(/* webpackChunkName: "draft-sources" */ "@/views/Drafts/DraftPanels/DraftSources.vue"),
            },{
              path: ":type/:id",
              name: "draft-form",
              props: (route) => ({ type: route.params.type, id: route.params.id }),
              component: () => import(/* webpackChunkName: "draft-form" */ "@/views/Drafts/DraftPanels/DraftForm.vue"),

            }
          ]
        },
        // -------
        // Opps <|
        // -------
        {
          // Search, listed first to allow page to load cold
          path: "/opportunities/search",
          name: "opps-search",
          component: () => import(/* webpackChunkName: "opps-search" */ "@/views/Opps/SearchOpps.vue")
        },{
          path: "/opportunities",
          component: () => import(/* webpackChunkName: "opps-index" */ "@/views/Opps/Opps.vue"),
          children: [
            {
              // Default - Featured Opps
              path: "",
              name: "opps-featured",
              component: () => import(/* webpackChunkName: "opps-featured" */ "@/views/Opps/OppPanels/FeaturedOpps.vue")
            },{
              // Create
              path: "create",
              name: "opps-create",
              component: () => import(/* webpackChunkName: "opps-create" */ "@/views/Opps/OppPanels/EditOpp.vue")
            },{
              // View
              path: ":tag19",
              props: (route) => ({ tag19: route.params.tag19 }),
              component: () => import(/* webpackChunkName: "opps-record" */ "@/views/Opps/OppPanels/ViewOpp.vue"),
              children: [
                {
                  // Default - View Opp
                  path: "",
                  name: "opps-details",
                  component: () => import(/* webpackChunkName: "opps-details" */ "@/views/Opps/OppPanels/OppDetails.vue")
                }, {
                  // Edit Opp
                  path: "edit",
                  name: "opps-edit",
                  component: () => import(/* webpackChunkName: "opps-edit" */ "@/views/Opps/OppPanels/EditOpp.vue")
                }, {
                  // Create News Draft
                  path: "news-draft",
                  name: "opps-news-draft",
                  component: () => import(/* webpackChunkName: "opps-news-draft" */ "@/views/Opps/OppPanels/OppNewsDraft.vue")
                }
              ]
            }
          ]
        },
        // -------------
        // Publicists <|
        // -------------
        {
          path: "/publicists",
          component: () => import(/* webpackChunkName: "generic" */ "@/views/Generic.vue"),
          children: [
            {
              // Directory
              path: "",
              name: "publicists-directory",
              component: () => import(/* webpackChunkName: "publicists-directory" */ "@/views/Publicists/Publicists.vue"),
            },{
              // View
              path: ":slug",
              name: "publicist-view",
              props: (route) => ({ slug: route.params.slug }),
              component: () => import(/* webpackChunkName: "publicists-contributor" */ "@/views/Publicists/Publicist.vue")
            }
          ],
        },
        // -----------
        // Sections <|
        // -----------
        {
          path: "/sections",
          component: () => import(/* webpackChunkName: "generic" */ "@/views/Generic.vue"),
          children: [
            {
              path: "",
              name: "sections-directory",
              component: () => import(/* webpackChunkName: "sections-directory" */ "@/views/Sections/Sections.vue"),
            },{
              path: ":slug",
              props: (route) => ({ slug: route.params.slug }),
              component: () => import(/* webpackChunkName: "sections-record" */ "@/views/Sections/Section.vue"),
              children: [{
                path: "",
                name: "sections-view",
                component: () => import(/* webpackChunkName: "sections-view" */ "@/views/Sections/SectionPanels/SectionView.vue"),
              },{
                path: "edit",
                name: "sections-edit",
                component: () => import(/* webpackChunkName: "sections-edit" */ "@/views/Sections/SectionPanels/SectionEdit.vue"),
              }]
            }
          ]
        },
        // --------
        // Staff <|
        // --------
        {
          path: "/staff",
          component: () => import(/* webpackChunkName: "generic" */ "@/views/Generic.vue"),
          children: [
            {
              // Directory
              path: "",
              name: "staff-directory",
              component: () => import(/* webpackChunkName: "staff-directory" */ "@/views/Staff/Staff.vue"),
            },{
              // View
              path: ":slug",
              props: (route) => ({ slug: route.params.slug }),
              component: () => import(/* webpackChunkName: "staff-contributor" */ "@/views/Staff/Contributor.vue"),
              children: [{
                path: "",
                name: "contributor-view",
                component: () => import(/* webpackChunkName: "staff-contributor-view" */ "@/components/Staff/ContributorView.vue"),
              },{
                // Edit
                path: "edit",
                name: "contributor-edit",
                component: () => import(/* webpackChunkName: "staff-contributor-edit" */ "@/components/Staff/ContributorEdit.vue"),
                redirect: "/staff/:slug/edit/public",
                children: [{
                  path: "public",
                  name: "contributor-edit-public",
                  component: () => import(/* webpackChunkName: "staff-contributor-edit-public" */ "@/components/Staff/StaffPublic"),
                },{
                  path: "editorial",
                  name: "contributor-edit-editorial",
                  component: () => import(/* webpackChunkName: "staff-contributor-edit-editorial" */ "@/components/Staff/StaffEditorial"),
                },{
                  path: "mailbox",
                  name: "contributor-edit-mailbox",
                  component: () => import(/* webpackChunkName: "staff-contributor-edit-mailbox" */ "@/components/Staff/StaffMailbox"),
                }]
              }]
            }
          ],
        },
        // -------
        // Wiki --
        // -------
        {
          path: '/wiki',
          name: 'wiki-directory',
          component: () =>
            import(/* webpackChunkName: "wiki-directory" */ "@/views/Wiki/WikiDirectory.vue")
        },
        {
          path: '/wiki/search',
          name: 'wiki-search',
          component: () =>
            import(/* webpackChunkName: "wiki-search" */ "@/views/Wiki/WikiSearch.vue")
        },
        {
          path: '/wiki/:wiki',
          name: 'wiki-index',
          component: () =>
            import(/* webpackChunkName: "wiki-view" */ "@/views/Wiki/WikiPage.vue")
        },
        {
          path: '/wiki/:wiki/*',
          name: 'wiki-view',
          component: () =>
            import(/* webpackChunkName: "wiki-view" */ "@/views/Wiki/WikiPage.vue")
        },
        // ---------------
        // Relase Notes <|
        // ---------------
        {
          path: "/release-notes",
          name: "release-notes",
          component: () =>
            import(/* webpackChunkName: "release-notes" */ "@/views/ReleaseNotes.vue"),
        },
        // ---------
        // Sample ==
        // ---------
        {
          path: "sample",
          name: "sample",
          component: () =>
            import(/* webpackChunkName: "sample" */ "@/views/Sample.vue"),
        },
        // ------------
        // Not Found --
        // ------------
        {
          path: "*",
          name: "not-found",
          component: () =>
            import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((_to, _from, next) => {
  // Reset alerts
  store.commit('resetAlerts')
  // Scroll to top
  window.scrollTo(0, 0)
  // Proceed
  next()
})

export default router
