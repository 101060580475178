<template>
  <div class="j-display">
    <label v-if="label">{{ label }}</label>
    <div class="value">
      <slot>
        <!-- Color -->
        <v-chip
          v-if="type === 'color'"
          class="j-output font-weight-bold"
          :color="value"
          text-color="white"
          small
          label
        >
          {{ value }}
        </v-chip>
        <!-- Date -->
        <template v-else-if="type === 'date'">
          {{ value | longDate }}
        </template>
        <!-- Markdown -->
        <j-markdown
          v-else-if="type === 'markdown'"
          :source="value"
        />
        <!-- Default -->
        <div
          v-else
          class="j-output-contents"
          v-html="value"
        />
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: 'string'
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

.j-display {

  font-family: $body-font !important;

  label {
    font-family: $heading-font !important;
    font-weight: bold;
    font-size: 75%;
  }
} 

</style>
