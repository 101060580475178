// ------------
// Opp Model ==
// ------------

import BModel from '@/models/base/BModel'
import Contact from './Contact'
import ContactList from './ContactList'
import Section from './Section'

import moment from 'moment'
import { required, email, empty } from 'vue-mc/validation'

export default class Opp extends BModel {

  bOptions() {
    return {
      remoteModel: 'opp',
      remotePath: '/api/opps',
      identifier: 'tag19'
    }
  }

  get _manifest() {
    return {
      title: {
        label: 'Title',
        validation: required
      },
      description: {
        label: 'Description',
        type: 'markdown'
      },
      tags: {
        label: 'Tags',
        type: 'tags'
      },
      released_on: {
        label: 'Release / Embargo Date',
        type: 'date'
      },
      contact: {
        label: 'Publicist (email)',
        type: 'email',
        validation: email.or(empty)
      },
      url: {
        label: 'Link / URL',
        type: 'url'
      },
      assignment_slug: {
        label: 'Assignment',
        type: 'autocomplete',
        options: {
          text: 'name',
          value: 'slug',
          query: this.getStaff
        }
      },
      publicist_slug: {
        label: 'Publicist',
        type: 'autocomplete',
        options: {
          text: 'email',
          value: 'slug',
          query: this.getPublicist
        }
      },
      section_slug: {
        label: 'Section',
        type: 'select',
        validation: required,
        options: [
          { text: "Event Reviews",  value: "event-reviews" }, 
          { text: "Features",       value: "features" }, 
          { text: "Gear Reviews",   value: "gear-reviews" }, 
          { text: "Info",           value: "info" }, 
          { text: "Interviews",     value: "interviews" }, 
          { text: "Music Reviews",  value: "music-reviews" }, 
          { text: "Print Reviews",  value: "print-reviews" }, 
          { text: "Screen Reviews", value: "screen-reviews" }
        ]
      },
      stage: {
        label: 'Stage'
      },
      // Associations
      assignee: {
        type: 'model',
        default: new Contact()
      },
      publicist: {
        type: 'model',
        default: new Contact()
      },
      section: {
        type: 'model',
        default: new Section()
      },
      // Uploads
      badge_src: {
        label: 'Opp Badge',
        type: 'cdn'
      },
      external_badge_src: {
        label: 'External Opp Badge'
      },
      published_at: {
        label: 'Published'
      },
      // Read Only
      tag19:        String,
      slug:         String,
      source:       Boolean,
      requested:    Boolean,
      updated_at:   Date,
      created_at:   Date,
      creator:      String,
      requests:     Array,
      public_opp:   String,
      available_stages: Array
    }
  }

  canSend(stage) {
    return this.available_stages.includes(stage)
  }

  routes() {
    return {
      ...super.routes(),
      request: '/api/opps/{tag19}/request'
    }
  }

  changed() {
    const allChanged = super.changed()
    // Return false values
    if (allChanged === false) { return false }
    // Return filtered values
    return allChanged.filter(field => !['comment_count', 'source'].includes(field))
  }

  get releaseDate () {
    const released_on = this.get('released_on')
    return released_on ? moment(released_on).format('M-D') : ''
  }

  // -----------
  // Requests ==
  // -----------

  makeRequest() {
    // Set up
    const method = this.getOption('methods.create');
    const route = this.getRoute('request');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    // Execute
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.markRequest)
  }

  cancelRequest() {
    // Set up
    const method = this.getOption('methods.delete');
    const route = this.getRoute('request');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    // Execute
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.markRequest)
  }

  markRequest(response) {
    this.set('requested', response.response.data.opp.requested)
  }

  // ------------
  // Parenting ==
  // ------------

  get parentInfo() {
    return {
      parent_id: this.get('tag19'),
      parent_type: 'opps'
    }
  }

  async getStaff(query) {
    if (query) {
      let itemList = new ContactList([], {}, {
        listing: 'staff-dropdown',
        query: query ? query : '',
        current_rows: 0,
      })
      await itemList.fetch()
      return itemList.getModels()
    } else {
      return [{
        name: this.get('assignee').get('name'),
        slug: this.get('assignee').get('slug'),
      }]
    }
  }

  async getPublicist(query) {
    if (query) {
      let itemList = new ContactList([], {}, {
        contact_type: 'publicists',
        listing: 'publicist-dropdown',
        query: query ? query : '',
        current_rows: 0,
      })
      await itemList.fetch()
      return itemList.getModels()
    } else {
      return [{
        email: this.get('publicist').get('email'),
        slug: this.get('publicist').get('slug'),
      }]
    }
  }

  get cdnMetadata() {
    return {
      folder: 'opps',
      context: {
        tag19: this.get('tag19')
      },
      tags: [
        'upload',
        this.get('title'),
        this.get('section').slug,
        moment(this.get('created_at')).format('Y-M')
      ]
    }
  }
}
