import store from '@/store'

import ArticleList from '@/models/ArticleList'
import ContactList from '@/models/ContactList'
import Section from '@/models/Section'
import SectionList from '@/models/SectionList'

export default {
  namespaced: true,
  state: {
    // UI
    addingEditor: false,
    // Individual Section
    section: new Section(),
    articles: new ArticleList(),
    editors: new ContactList(),
    // Section Listings
    magazine: new SectionList([], {}, { listing: 'volume-magazine' }),
    news: new SectionList([], {}, { listing: 'volume-news' }),
    columns: new SectionList([], {}, { listing: 'volume-columns' })
  },
  getters: {
    // UI
    loading(state) {
      return state.section.loading
        || state.magazine.loading
        || state.columns.loading
    },
    show(state) {
      return !state.section.fatal
    },
    // Policy
    canEdit(state) {
      return store.getters['isAdmin'] || !!state.editors.find({ slug: store.getters['userSlug'] })
    }
  },
  mutations: {
    NEW_SECTION: (state) => state.section = new Section(),
    SHOW_EDITOR_FIELD: state => state.addingEditor = true,
    HIDE_EDITOR_FIELD: state => state.addingEditor = false
  },
  actions: {

    // Fetch all the listings
    fetchSections({ dispatch, state }) {
      // console.log('sections/fetchSections')
      const listings = ['magazine', 'news', 'columns']
      listings.forEach((listing) => {
        dispatch('fetchCollection', state[listing], { root: true })
      })
    },

    // Fetch a section from its slug
    fetchSection({ commit, dispatch, state }, slug) {
      // console.log('sections/fetchSection')
      commit('NEW_SECTION')
      if (slug) {
        state.section.set({ slug })
        dispatch('fetchModel', state.section, { root: true }).then(() => { 
          state.editors.set({ listing: `section-editors-for-${slug}`, rows: 0})
          state.editors.fetch()
        })
      }
    },

    // Fetch the articles for a given section (paginated)
    fetchArticles({ dispatch, state }, { slug, page = 1, query = '' }) {
      // console.log('sections/fetchArticles', slug, page, query)
      // Set the article listing for this section
      state.articles.set({
        listing: `section-${slug}`,
        current_page: page,
        query
      })
      // Fetch articles
      dispatch('fetchCollection', state.articles, { root: true })
    },

    // Save the current section
    saveSection({ dispatch, state }) {
      // console.log('sections/saveSection')
      return dispatch('saveModel', state.section, { root: true })
    },

    // Add the editor to the section
    addEditor ({ commit, dispatch, state }) {
      // console.log('sections/addEditor')
      state.section.addEditor().then(() => {
        commit('HIDE_EDITOR_FIELD')
        state.section.set('editor', null)
        dispatch('fetchCollection', state.editors, { root: true })
      }).catch(
        // Error Handling
        error => dispatch('processError', error, { root: true })
      )
    },

    // Remove an editor from the section
    removeEditor ({ dispatch, state }, editor) {
      // console.log('sections/removeEditor', editor)
      state.section.set({ editor })
      state.section.removeEditor().then(() => {
        state.section.set('editor', null)
        dispatch('fetchCollection', state.editors, { root: true })
      }).catch(
        // Error Handling
        error => dispatch('processError', error, { root: true })
      )
    }
  }
}
