// ---------------------
// Comment List Model ==
// ---------------------

import BCollection from './base/BCollection'
import Comment from './Comment'

export default class CommentListing extends BCollection {

  bOptions() {
    return {
      model: Comment,
      remoteModel: 'comments'
    }
  }

  routes() {
    return {
      fetch: '/api/{parent_type}/{parent_id}/comments'
    }
  }
}