// ----------------------
// Mailbox Label Model ==
// ----------------------

import BModel from '@/models/base/BModel'

import { boolean, required } from 'vue-mc/validation'

export default class MailboxLabel extends BModel {

  bOptions() {
    return {
      remoteModel: 'mailbox_label'
    }
  }

  get _manifest() {
    return {
      label: {
        label: 'Import Label',
        type: 'select'
      },
      section_slug: {
        label: 'To Section',
        type: 'select',
        validation: required,
        options: [
          { text: "Event Reviews",  value: "event-reviews" }, 
          { text: "Features",       value: "features" }, 
          { text: "Gear Reviews",   value: "gear-reviews" }, 
          { text: "Info",           value: "info" }, 
          { text: "Interviews",     value: "interviews" }, 
          { text: "Music Reviews",  value: "music-reviews" }, 
          { text: "Print Reviews",  value: "print-reviews" }, 
          { text: "Screen Reviews", value: "screen-reviews" }
        ]
      },
      _destroy: {
        label: 'Delete',
        type: boolean,
        default: false
      },
      // Read Only
      created_at: Date,
      updated_at: Date
    }
  }
}
