// ---------------------
// Announcement Model ==
// ---------------------

import Image from './Image'
import BModel from '@/models/base/BModel'

import { required } from 'vue-mc/validation'

export default class Section extends BModel {

  bOptions() {
    return {
      remoteModel: 'announcement',
      remotePath: '/api/announcements',
      identifier: 'tag19'
    }
  }

  get _manifest () {
    return {
      title: {
        label: 'Title',
        validation: required
      },
      description: {
        label: 'Description',
        type: 'markdown'
      },
      body: {
        label: 'Body',
        type: 'textarea'
      },
      stage: {
        label: 'Stage',
        type: 'select',
        default: 'published',
        options: [
          { text: 'Draft', value: 'draft' },
          { text: 'Published', value: 'published' }
        ]
      },
      section_slug: {
        label: 'Type',
        type: 'select',
        default: 'staff-announcements',
        options: [
          { text: 'Staff', value: 'staff-announcements' },
          { text: 'Publicists', value: 'publicist-announcements' }
        ]
      },
      // Associations
      badge: {
        type: 'model',
        default: new Image
      },
      remote_badge_url: {
        label: 'URL for Badge',
        type: 'url'
      },
      // Read Only
      tag19:      String,
      slug:       String,
      updated_at: Date,
      created_at: Date,
      creator:    String
    }
  }

  get parentInfo () {
    return {
      parent_id: this.get('tag19'),
      parent_type: 'announcements'
    }
  }
}
