// ----------------
// Article Model ==
// ----------------

import BModel from '@/models/base/BModel'
import EmbedList from './EmbedList'
import ImageList from './ImageList'
import ContactList from './ContactList'
import SectionList from './SectionList'
import Section from './Section'

import moment from 'moment'
import { required } from 'vue-mc/validation'

export default class Article extends BModel {

  bOptions() {
    return {
      remoteModel: 'article',
      remotePath: '/api/articles',
      identifier: 'tag19'
    }
  }

  get _manifest() {
    return {
      slug: {
        label: 'Slug'
      },
      title: {
        label: 'Title',
        validation: required
      },
      description: {
        label: 'Description',
        type: 'markdown'
      },
      body: {
        label: 'Text',
        type: 'textarea'
      },
      byline: {
        label: 'Byline'
      },
      tags: {
        label: 'Tags',
        type: 'tags'
      },
      subsection: {
        label: 'Subsection'
      },
      released_on: {
        label: 'Release / Embargo Date',
        type: 'date'
      },
      published_at: {
        label: 'Publication',
        type: 'datetime',
        options: {
          enableTime: true,
          minuteIncrement: 15
        }
      },
      stage: {
        label: 'Stage'
      },
      featured: {
        label: 'Featured',
        type: 'switch',
        default: false
      },
      contact: {
        label: 'Contact'
      },
      section_slug: {
        label: 'Section',
        type: 'autocomplete',
        options: {
          text: 'name',
          value: 'slug',
          query: this.getSections
        }
      },
      creator_slug: {
        label: 'Creator',
        type: 'autocomplete',
        options: {
          text: 'name',
          value: 'slug',
          query: this.getStaff
        }
      },
      opp_tag19: {
        label: 'Assignment'
      },
      proofed: {
        label: 'Proofing Mark',
        type: 'boolean'
      },
      // Associations
      remote_badge_url: {
        label: 'URL for Badge',
        type: 'url'
      },
      section: {
        type: 'model',
        default: new Section
      },
      images: {
        type: 'collection',
        default: new ImageList
      },
      embeds: {
        type: 'collection',
        default: new EmbedList
      },
      // Uploads
      badge_src: {
        label: 'Article Badge',
        type: 'cdn'
      },
      // Read Only
      tag19:      String,
      updated_at: Date,
      created_at: Date,
      creator:    String,
      custody:    Object,
      proofing:   Array,
      opp_title:  String,
      available_stages: Array
    }
  }

  get releaseDate () {
    const released_on = this.get('released_on')
    return released_on ? moment(released_on).format('M-D') : ''
  }

  // ---------
  // Policy ==
  // ---------

  get policy() {
    const sectionSlug = this.get('section_slug')
    const userSlug = this.currentUser.slug
    const checkedOut = !!this.get('custody').custodian
    const notCheckedOut = !checkedOut
    const isEditor = this.currentUser.isEditor(sectionSlug)
    const isOwner = this.currentUser.slug === this.get('creator_slug')
    const isDraft = this.get('stage') == 'Draft'
    const editable = isEditor || (isOwner && isDraft)
    const custodian = this.get('custody').custodian_slug === userSlug
    const editing = checkedOut && editable && custodian
    const notEditing = !editing
    const isPublished = this.stage === 'Published'
    const isNotPublished = !isPublished
    return {
      editing,
      editable,
      showTools: notCheckedOut && (isEditor || editable),
      showIndex: notEditing,
      showActions: checkedOut && editing,
      sendToDraft: this.send('draft'),
      sendToCopy: this.send('copy'),
      sendToReleased: this.send('released') && isNotPublished && isEditor,
      sendToDeleted: this.send('deleted') && isNotPublished && isEditor,
      republish: this.send('released') && isPublished && isEditor
    }
  }

  send(stage) {
    return this.available_stages.includes(stage)
  }

  // ------------
  // Parenting ==
  // ------------

  get parentInfo() {
    return {
      parent_id: this.get('tag19'),
      parent_type: 'articles'
    }
  }

  // ------------
  // Republish ==
  // ------------

  republish() {
    const method = this.getOption('methods.update');
    const route = this.getRoute('republish');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    this.loading = true
    return this.createRequest({ method, url, headers })
      .send()
      .then(() => this.loading = false)
      .catch((error) => {
        this.loading = false
        console.log(error)
        throw error
      })
  }

  // ----------
  // Custody ==
  // ----------

  checkOut() {
    const method = this.getOption('methods.update');
    const route = this.getRoute('checkOut');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.onFetchSuccess)
  }

  checkIn() {
    const method = this.getOption('methods.update');
    const route = this.getRoute('checkIn');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.onFetchSuccess)
  }

  // ---------
  // Stages ==
  // ---------

  routes() {
    return {
      ...super.routes(),
      republish: '/api/articles/{tag19}?republish=1',
      checkOut: '/api/articles/{tag19}?custody=check_out',
      checkIn: '/api/articles/{tag19}?custody=check_in'
    }
  }

  changed() {
    const allChanged = super.changed()
    // Return false values
    if (allChanged === false) { return false }
    // Return filtered values
    return allChanged.filter(field => !['comment_count'].includes(field))
  }

  async getSections(query) {
    const name = this.get('section').get('name')
    if (query && name) {
      let itemList = new SectionList([], {}, {
        listing: 'section-dropdown',
        query: query ? query : '',
        current_rows: 0,
      })
      await itemList.fetch()
      return itemList.getModels()
    } else {
      return [{
        name,
        slug: this.get('section_slug'),
      }]
    }
  }

  async getStaff(query) {
    if (query && query != this.get('creator')) {
      let itemList = new ContactList([], {}, {
        listing: 'staff-dropdown',
        query: query ? query : '',
        current_rows: 0,
      })
      await itemList.fetch()
      return itemList.getModels()
    } else {
      return [{
        name: this.get('creator'),
        slug: this.get('creator_slug'),
      }]
    }
  }

  get cdnMetadata() {
    return {
      folder: 'articles',
      context: {
        tag19: this.get('tag19')
      },
      tags: [
        'upload',
        this.get('title'),
        this.get('section').slug,
        moment(this.get('created_at')).format('Y-M')
      ]
    }
  }

  get featuredImageCropping () {
    if (['music-reviews','staff-picks'].includes(this.get('section').get('slug'))) {
      return { aspectRatioName: 'square', fixedWidth: 500 }
    } else {
      return { aspectRatioName: 'widescreen', fixedWidth: 800 }
    }
  }
}
