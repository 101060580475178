import BModel from '@/models/base/BModel'
import {
  required
} from 'vue-mc/validation'

export default class WikiBinary extends BModel {

  _name () {
    return 'wiki_binary'
  }

  options() {
    return {
      ...super.options(),
      patch: false
    }
  }

  manifest () {
    return {
      title: {
        label: 'Title'
      },
      slug: {
        label: 'Slug'
      },
      description: {
        label: 'Description',
        type: 'textarea'
      },
      binary: {
        label: 'Binary',
        type: 'file',
        validation: required
      },
      mime:             String,
      size:             String,
      wiki:             String,
      path:             String,
      parent_directory: String,
      parent_name:      String
    }
  }

  // -----------
  // Binaries --
  // -----------

  get src() {
    return `/wiki_source/binary/${this.wiki}/${this.parent_directory}/${this.parent_name}/${this.id}`.replace('//','/')
  }

  get isImage() {
    return this.get('mime').startsWith('image/')
  }

  // -----------
  // Metadata --
  // -----------

  getSaveData() {
    const pageData = this.getPageData()
    let data = new FormData()
    // Add original data
    for (let name in pageData['metadata']) {
      // Only include non-null values because FormData
      // can't hack anything other than a string or a file.
      // Since it shouldn't be possible for a user to set a
      // null value, we are assuming the original value is
      // null and should remain unchanged.
      if (pageData[name] !== null) {
        data.append(this.remoteFieldName(`metadata][${name}`), pageData['metadata'][name]) // hangs head in shame
      }
    }
    // Add body
    data.append(this.remoteFieldName('body'), pageData['body'])
    // Add image
    data.append(this.remoteFieldName('binary'), this.get('binary'))
    // Return formdata
    return data
  }

  getPageData() {
    let originalData = this.attributes
    let pageData = { metadata: {} }
    // Filter out the fields we don't save on the page
    const skipFields = [
      'id',
      'path',
      'author_name',
      'author_email',
      'last_update',
      'url'
    ]
    for (let name in originalData) {
      switch (name) {
        // Description
        case 'description':
          pageData.body = originalData[name]
          break
        // File
        case 'image':
          pageData.image = originalData[name]
          break
        // Everything else
        default:
          // Only add if it's not a skipped field
          if (!skipFields.includes(name)) {
            pageData.metadata[name] = originalData[name]
          }
          break
      }
    }
    return pageData
  }

  routes() {
    return {
      fetch:  '/wiki_source/binary/{wiki}/{parent_directory}/{parent_name}/{id}',
      create: '/wiki_source/binary/{wiki}/{path}/create',
      save:   '/wiki_source/binary/{wiki}/{parent_directory}/{parent_name}/{id}',
      delete: '/wiki_source/binary/{wiki}/{parent_directory}/{parent_name}/{id}',
    }
  }
}
