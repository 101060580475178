import ArticleList from '@/models/ArticleList'
import OppList from '@/models/OppList'

export default {
  namespaced: true,
  state: {
    // Page Info
    pageInfo: { title: 'Dashboard' },
    // Listings
    currentArticles: new ArticleList([], {}, { current_rows: 0 }),
    currentAssignments: new OppList([], {}, { current_rows: 0 }),
    recentArticles: new ArticleList([], {}, { listing: 'recent', current_rows: 10 }),
    featuredOpps: new OppList([], {}, { listing: 'featured', current_rows: 10 }),
  },
  getters: {
    // UI
    loading(state) {
      return state.currentArticles.loading
        || state.currentAssignments.loading
        || state.featuredOpps.loading
        || state.recentArticles.loading
    }
  },
  actions: {

    // Get dashboard content
    async fetchDashboard({ dispatch, state, rootState }) {
      // console.log('dashboard/fetchCurrentArticles')
      // Get the current user's slug
      const slug = rootState.auth.profile.slug
      // Set personalized collections
      state.currentArticles.set({ listing: `copy-for-${slug}` })
      state.currentAssignments.set({ listing: `assigned-to-${slug}` })
      // Fetch all the listings
      const listings = ['currentArticles', 'currentAssignments', 'recentArticles', 'featuredOpps']
      listings.forEach((listing) => {
        dispatch('fetchCollection', state[listing], { root: true })
      })
    },

    // Get featured opps
    fetchFeaturedOpps({ dispatch, state }) {
      console.log('fetchFeaturedOpps')
      return dispatch('fetchCollection', state.featuredOpps, { root: true })
    }
  }
}
