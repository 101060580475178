<template>
  <v-img
    v-bind="$attrs"
    class="graphic-source align-center justify-center"
    :class="{ round }"
    @click="$emit('click')"
  >
    <slot />
  </v-img>
</template>

<script>
export default {
  name: 'JexyGraphic',
  props: {
    round: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.graphic-source {
  // @extend %j-background-medium;
  // clip-path: url(#superellipse);
}

.graphic-source.round {
  border-radius: 50%;
}

</style>