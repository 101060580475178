<template>

  <!-- Menu -->
  <v-menu offset-y>
    <template #activator="{ on }">

      <!-- Activating Button -->
      <v-btn
        :color="buttonColor"
        :icon="buttonRounded"
        :rounded="buttonRounded"
        :text="buttonText"
        :class="buttonClass"
        v-on="on"
      >
        {{ buttonLabel }}
        <j-icon :icon="icon" :class="{'ml-2': buttonType !== 'icon'}" />
      </v-btn>

    </template>
    <v-list>

      <!-- Menu Items -->
      <slot />

    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'JexyMenu',
  props: {
    icon: {
      type: String,
      default: 'chevron-down'
    },
    label: {
      type: String,
      default: 'Tools'
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    buttonClass: {
      type: String,
      default: null
    },
    buttonColor: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonLabel () {
      return this.buttonType === 'icon' ? '' : this.label
    },
    buttonRounded () {
      return this.buttonType === 'icon'
    },
    buttonText () {
      return this.buttonType === 'text'
    }
  }
}
</script>
