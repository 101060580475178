import ArticleList from '@/models/ArticleList'
import Contact from '@/models/Contact'
import ContactList from '@/models/ContactList'
import Mailbox from '@/models/Mailbox'

export default {
  namespaced: true,
  state: {
    // Search
    query: new ContactList([], {}, { listing: 'staff' }),
    // Individual Contact
    contact: new Contact(),
    articles: new ArticleList(),
    // Contact Listing
    staff: new ContactList([], {}, { listing: 'staff' }),
    editors: new ContactList([], {}, { listing: 'editors' }),
    // Mailbox
    mailbox: new Mailbox()
  },
  getters: {
    // UI
    loading(state) {
      return state.contact.loading
        || state.staff.loading
        || state.editors.loading
        || state.query.loading
    },
    show(state) {
      return !state.contact.fatal
    },
    hasMailbox(state) {
      return state.contact.mailbox
    }
  },
  mutations: {
    // Init
    NEW_CONTACT: (state) => state.contact = new Contact(),
    RESET_CONTACT (state) {
      if (state.contact.modified) {
        state.contact.reset(state.contact.changed())
      }
    },
    // Search
    NEW_QUERY: (state) => { state.query = new ContactList() }
  },
  actions: {

    // Fetch query from server
    fetchQuery({ state }) {
      // console.log('staff/fetchQuery')
      return state.query.fetch()
    },

    // Fetch the staff listing
    fetchStaff ({ dispatch, state }, page = 1) {
      // console.log('staff/fetchStaff')
      state.staff.set('current_page', page)
      dispatch('fetchCollection', state.staff, { root: true })
    },

    // Fetch the editors listing
    fetchEditors ({ dispatch, state }) {
      // console.log('staff/fetchEditors')
      dispatch('fetchCollection', state.editors, { root: true })
    },

    // Fetch an individual contact
    fetchContact ({ commit, dispatch, state }, slug) {
      // console.log('staff/fetchContact', slug)
      commit('NEW_CONTACT')
      state.contact.set({ slug })
      dispatch('fetchModel', state.contact, { root: true })
    },

    // Save the current contact
    saveContact ({ dispatch, state }) {
      // console.log('contacts/saveContact')
      return dispatch('saveModel', state.contact, { root: true })
    },

    // TODO - Move to mailbox store
    // Fetch the mailbox for a contact
    fetchMailbox({ dispatch, state }) {
      // console.log('fetchMailbox')
      // Set the owner and fetch
      state.mailbox.set('owner_slug', state.contact.get('slug'))
      return dispatch('fetchModel', state.mailbox, { root: true })
    },

    // Create a mailbox for a contact
    async createMailbox({ dispatch, state }) {
      // console.log('createMailbox')
      // Get the mailbox
      const mailbox = state.contact.get('mailbox')
      // Set the owner and create
      mailbox.set('owner_slug', state.contact.get('slug'))
      dispatch('saveModel', mailbox, { root: true })
    },

    // Save the mailbox for a contact
    async saveMailbox({ dispatch, state }) {
      // console.log('saveMailbox')
      // Save the mailbox
      dispatch('saveModel', state.mailbox, { root: true })
    },

    // Disable a mailbox for a contact
    async disableMailbox({ dispatch, state }) {
      // console.log('disableMailbox')
      // Get the mailbox
      const mailbox = state.contact.get('mailbox')
      // Balete it
      dispatch('deleteModel', mailbox, { root: true })
    },

    // Fetch the articles for a given contributor (paginated)
    fetchArticles({ dispatch, state }, { slug, page = 1, query = '' }) {
      // console.log('staff/fetchArticles', slug, page, query)
      // Get the article listing for this slug
      state.articles.set({
        listing: `by-${slug}`,
        current_page: page,
        query
      })
      // Fetch articles
      dispatch('fetchCollection', state.articles, { root: true })
    },

    // Disable a contact
    async disableContact({ commit, dispatch, state }) {
      // console.log('images/disableContact')
      dispatch('deleteModel', state.contact, { root: true }).then(() => {
        commit('dialogClose', 'disable-staff', { root: true })
        dispatch('push', { name: 'staff-directory' }, {root: true})
      })
    }
  }
}
