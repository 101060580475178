<template>
  <div v-html="content" class="jexy-markdown"/>
</template>

<script>

import MarkdownIt from "markdown-it"

const quicktags = {

  // Headers
  ft: { prefix: '#', class: 'main-title' },
  fh: { prefix: '#', class: 'hidden-title' },
  fs: { prefix: '##', class: 'main-subtitle' },

  // Info
  it: { prefix: '#', class: 'main-title' },
  is: { prefix: '##', class: 'main-subtitle' },

  // Images
  im: { tag: 'span', class: 'item-image' },

  // Music Reviews
  ma: { prefix: '#', class: 'music-artist' },
  mt: { prefix: '##', class: 'music-title' },
  ml: { prefix: '###', class: 'music-label' },

  // Screen Reviews
  st: { prefix: '#', class: 'screen-title' },
  sc: { prefix: '##', class: 'screen-cast' },
  ss: { prefix: '###', class: 'screen-studio' },

  // Print Reviews
  pt: { prefix: '#', class: 'print-title' },
  pa: { prefix: '##', class: 'print-author' },
  pp: { prefix: '###', class: 'print-publisher' },

  // Gear Reviews
  gp: { prefix: '#', class: 'gear-product' },
  gt: { prefix: '##', class: 'gear-type' },
  gm: { prefix: '###', class: 'gear-manufacturer' },

  // Staff Picks
  xa: { prefix: '#', class: 'staff-pick-artist' },
  xt: { prefix: '##', class: 'staff-pick-title' },
  xl: { prefix: '###', class: 'staff-pick-label' },

  // Body
  bp: { class: 'body-plain' },
  bb: { prefix: '####', class: 'body-byline' },
  bf: { class: 'body-first' },
  bm: { class: 'body-plain' },
  bc: { class: 'body-center' },
  bs: { prefix: '###', class: 'body-subhead' },
  bq: { class: 'body-question' },
  ba: { prefix: '>', class: 'body-answer' },

  // Reviews
  ra: { class: 'review-address' },
  rb: { prefix: '####', class: 'review-byline' },

  // Live
  lh: { prefix: '#', class: 'live-headline' },
  ls: { prefix: '##', class: 'live-supporting' },
  lp: { prefix: '###', class: 'live-location' },

  // Wet Ink
  wa: { prefix: '#', class: 'music-artist' },
  wt: { prefix: '##', class: 'music-title' },
  wl: { prefix: '###', class: 'music-label' },
  wf: { class: 'body-first' },
  wm: { class: 'body-middle' },
  wd: { class: 'review-address' },
  wb: { prefix: '####', class: 'review-byline' },

  // HTML
  ht: { tag: 'span', class: 'html' },

  // Error
  xx: { tag: 'div', class: 'red--text' }
}

export default {
  name: 'JexyMarkdown',
  props: {
    source: {
      type: String,
      default: ''
    },
    images: {
      type: Object,
      default: null
    },
    embeds: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      md: new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true
      })
    }
  },
  computed: {
    content () {
      if (this.source) {
        let source = this.preRender(this.source)
        source = this.md.render(source)
        source = this.postRender(source)
        return source
      } else {
        return ''
      }
    }
  },
  methods: {
    findMedia (name) {
      // Look in images
      let media = this.images.find({ title: name })
      if (media) {
        return media
      } else {
        // Look in embeds
        return this.embeds.find({ title: name })
      }
    },
    mediaParser(match, name) {
      // Match against images
      const media = this.findMedia(name)
      // Continue if we have a match
      if (media) {
        let mediaTag = ''
        // Start with iframe (embed) or basic image
        mediaTag += media.source_code || `<img src="${media.src.display}" alt="image: ${media.title}" class="image-graphic" />`
        // Do we have a credit?
        if (media.credit) { mediaTag += `<div class="media-credit">${media.credit}</div>` }
        // Do we have a caption?
        if (media.caption) { mediaTag += `<div class="media-caption">${media.caption}</div>` }
        // Manage alignment
        const alignments = {
          fill: "media-fill my-4",
          left: "media-left mr-4 mb-4",
          right: "media-right ml-4 mb-4",
          center: "media-center mb-4"
        }
        const alignment = alignments[media.align] || alignments.fill
        // Return composed tag
        return `<div class="${alignment}">${mediaTag}</div>`
      } else {
        // No match, return original
        return match
      }
    },
    quicktagPreParser(match, tag) {
      // Add any prefixes if we have them
      const tagInfo = quicktags[tag]
      if (tagInfo && tagInfo.prefix) {
        return `${tagInfo.prefix} ${match}`
      } else {
        return match
      }
    },
    quicktagPostParser(match, openingTag, closingTag, quickTag) {
      // Add classes for quicktag, default to -xx for unknown types
      const tagInfo = quicktags[quickTag] || quicktags['xx']
      // Inject class
      return `${openingTag} class="${tagInfo.class}">`
    },
    preRender(markdown) {
      // Don't pre-render unless we have images or embeds
      if (!this.images || !this.embeds) return markdown
      // Replace media
      const mediaNames = this.images.map('title').concat(this.embeds.map('title'))
      for (const mediaName of mediaNames) {
        const mediaRegex = new RegExp(`\\[\\[(${mediaName})\\]\\]`, 'g')
        markdown = markdown.replace(mediaRegex, this.mediaParser)
      }
      // Preprocess quicktags
      const quicktagRegex = /^-(..)\s+/mg
      markdown = markdown.replace(quicktagRegex, this.quicktagPreParser)
      // Return pre-rendered text
      return markdown
    },
    postRender(html) {
      // Replace quicktags with corresponding classes
      const quicktagRegex = /^(<[^>]+)(>-(..)\s+)/mg
      html = html.replace(quicktagRegex, this.quicktagPostParser)
      // Replace end block
      html = html.replace('&amp;end;', '<span class="end-block">&bull;</span>')
      return html
    }
  }
}
</script>

<style lang="scss">

.jexy-markdown.p:last-child {
  margin-bottom: 0 !important;
}

</style>
