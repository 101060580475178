// -----------------------
// Publicist List Model ==
// -----------------------

import BCollection from './base/BCollection'
import Publicist from './Publicist'

export default class PublicistListing extends BCollection {

  bOptions() {
    return {
      model: Publicist,
      remoteModel: 'publicists'
    }
  }

  routes() {
    return {
      fetch: '/api/publicists/?l={listing}&q={query}&p={current_page}&r={current_rows}'
    }
  }

  defaults() {
    return {
      ...this.paginationFields
    }
  }
}
