<template>

  <!-- Expanded Search -->
  <form v-if="expanded" @submit.prevent="handleAction">
    <v-text-field
      v-model="q"
      ref="search"
      placeholder="Search"
      :append-icon="searchIcon"
      flat
      outlined
      rounded
      dense
      hide-details
      single-line
      class="search-input"
      @click:append="handleAction"
    />
  </form>

  <!-- Condensed Search -->
  <j-button
    v-else
    type="icon"
    icon="search"
    :help="help"
    @action="handleExpand"
  />


</template>

<script>

export default {
  name: 'Search',
  // -------------
  // Properties ==
  // -------------
  props: {
    query: {
      type: String,
      default: null
    },
    searchPage: {
      type: String,
      default: null
    },
    help: {
      type: String,
      default: 'Search'
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      expanded: this.open || this.$route.name.includes('search'),
      q: this.query
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    searchIcon () {
      return this.q ? 'fa-search' : 'fa-times'
    }
  },
  // ----------
  // Mounted ==
  // ----------
  mounted () {
    this.focus()
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    focus () {
      if (this.expanded) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.search && this.$refs.search.$refs) {
              this.$refs.search.$refs.input.focus()
            }
          }, 0)
        })
      }
    },
    handleExpand () {
      this.$emit('expand')
      this.expanded = true
      this.focus()
    },
    handleAction () {
      if (this.q) {
        this.$emit('search', this.q)
      } else {
        this.$emit('clear', this.q)
        this.expanded = false
      }
    }
  }
}
</script>

<style lang="scss">

.search-input {
  font-family: $body-font !important;
  background-color: white;
}

</style>
