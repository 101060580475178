import Article from '@/models/Article'
import ArticleList from '@/models/ArticleList'

export default {
  namespaced: true,
  state: {
    // UI
    articleTab: 'embeds',
    custodyMessage: '',
    // Search
    query: new ArticleList(),
    // Individual Items
    article: new Article(),
    // Listings
    copy_desk: new ArticleList([], {}, { listing: 'copy', current_rows: 0 }),
    scheduled: new ArticleList([], {}, { listing: 'scheduled' }),
    recent: new ArticleList([], {}, { listing: 'recent' }),
    drafts: new ArticleList([], {}, { listing: 'drafts', current_rows: 0 })
  },
  getters: {
    // UI
    loading(state) {
      return state.article.loading
        || state.copy_desk.loading
        || state.scheduled.loading
        || state.recent.loading
        || state.drafts.loading
        || state.query.loading
    }
  },
  mutations: {
    // UI
    SET_TAB: (state, tab) => state.articleTab = tab,
    // Init
    NEW_ARTICLE: (state) => state.article = new Article(),
    RESET_ARTICLE(state) {
     if (state.article.modified) {
       state.article.reset(state.article.changed())
      }
    },
    // Search
    NEW_QUERY: (state) => { state.query = new ArticleList() }
  },
  actions: {

    // Fetch query from server
    fetchQuery ({ state }) {
      // console.log('articles/fetchQuery')
      if (state.query.q) {
        return state.query.fetch()
      }
    },

    // Fetch all the listings
    fetchArticles ({ dispatch, state }) {
      // console.log('articles/fetchArticles')
      // Fetch all the listings
      const listings = ['copy_desk','scheduled','recent','drafts']
      listings.forEach((listing) => {
        dispatch('fetchCollection', state[listing], { root: true })
      })
    },

    // Save publication dates for scheduled articles
    saveSchedule ({ dispatch, state }) {
      // console.log('articles/saveSchedule')
      state.scheduled.saving = true
      state.scheduled.getModels().forEach((article) => {
        if (article.changed()) { dispatch('saveModel', article, { root: true }) }
      })
      state.scheduled.sort('published_at')
      state.scheduled.saving = false
    },

    // Fetch a given article
    fetchArticle ({ commit, dispatch, state }, tag19) {
      // console.log('articles/fetchArticle', tag19)
      commit('NEW_ARTICLE')
      // Fetch given article
      if (tag19) {
        state.article.set('tag19', tag19)
        dispatch('fetchModel', state.article, { root: true })
      }
    },

    // Check out the article, or report if it's already checked out
    checkOutArticle ({ commit, state }) {
      // console.log('articles/checkOutArticle')
      // Reset custody message
      state.custodyMessage = ''
      // Can we check it out?
      return state.article.checkOut().then(() => {
        // Set tab to metadata if good
        commit('SET_TAB', 'metadata')
      }).catch((error) => {
        // Set custody message if bad
        state.custodyMessage = error.response.response.data.base[0]
      })
    },

    // Check in an article
    checkInArticle ({ commit, state }) {
      // console.log('articles/checkInArticle')
      return state.article.checkIn().then(() => {
        // Set tab to metadata if good
        commit('SET_TAB', 'comments')
      })
    },
    
    // Save the article
    saveArticle ({ state }) {
      // console.log('articles/saveArticle')
      return state.article.save().then(() => {
        // Scroll to the top
        window.scrollTo(0, 0)
      })
    },

    // Cancel editing an article
    cancelEditArticle ({ commit, state }) {
      // console.log('articles/cancelEditArticle')
      // Reset any changes
      commit('RESET_ARTICLE')
      // Set tab to comments
      commit('SET_TAB', 'metadata')
      // Check in article
      state.article.checkIn()
    },

    // Send the article to a given stage
    sendArticle ({ commit, dispatch, state }, stage) {
      // console.log('articles/sendArticle', stage)
      if (stage === 'deleted') {
        // Confirm deletion
        commit('dialogOpen', 'delete-article', { root: true })
      } else {
        // Otherwise just send it
        state.article.set({ stage })
        dispatch('saveModel', state.article, { root: true })
      }
    },

    // Republish the article
    republishArticle ({ state }) {
      // console.log('articles/republishArticle')
      return state.article.republish()
    },
  
    // Execute an article deletion
    async confirmDeletion ({ commit, dispatch, state }) {
      // console.log('articles/confirmDeletion', comment)
      // Send article to baleted
      state.article.set('stage', 'deleted')
      dispatch('saveModel', state.article, { root: true }).then(() => {
        // Hide dialog
        commit('dialogClose', 'delete-article', { root: true })
        // Send to index
        dispatch('push', ({ name: 'articles-index' }), { root: true })
      })
    },

    // Cancel an article deletion
    cancelDeletion ({ commit }) {
      // console.log('articles/cancelDeletion', stage)
      // Hide dialog
      commit('dialogClose', 'delete-article', { root: true })
    }
  }
}
