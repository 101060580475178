import Vue from 'vue'

export const dialogs = {
  state: {
    dialogs: {}
  },
  getters: {
    dialogStatus: (state) => (dialog) => state[dialog]
  },
  mutations: {
    dialogOpen(state, dialog) { Vue.set(state, dialog, true) },
    dialogClose(state, dialog) { Vue.set(state, dialog, false) }
 }
}