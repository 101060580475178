import Draft from '@/models/Draft'
import Opp from '@/models/Opp'
import OppSource from '@/models/OppSource'
import OppList from '@/models/OppList'
import Recommendation from '@/models/Recommendation'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    // Search
    query: new OppList(),
    // UI
    showIndex: false,
    loadingMoreOpps: false,
    // Individual Opp
    opp: new Opp(),
    oppSource: new OppSource(),
    // Opp Listing
    available: new OppList([], {}, { listing: 'available', current_rows: 20 }),
    featured: new OppList([], {}, { listing: 'featured', current_rows: 20 }),
    // Assignments
    requested: new OppList([], {}, { listing: 'requested', current_rows: 0 }),
    proposed: new OppList([], {}, { listing: 'proposed', current_rows: 0 }),
    assigned: new OppList([], {}, { listing: 'assigned', current_rows: 0 }),
    // Recommendation
    recommendation: new Recommendation(),
    // News Draft
    newsDraft: new Draft(),
  },
  getters: {
    // UI
    loading(state) {
      return state.opp.loading
        || state.available.loading
        || state.featured.loading
        || state.requested.loading
        || state.proposed.loading
        || state.query.loading
        || state.loadingMoreOpps
    }
  },
  mutations: {
    // UI
    START_LOADING_MORE_OPPS: (state) => state.loadingMoreOpps = true,
    STOP_LOADING_MORE_OPPS: (state) => state.loadingMoreOpps = false,
    // Init
    NEW_OPP: (state) => state.opp = new Opp(),
    NEW_RECOMMENDATION: (state) => state.recommendation = new Recommendation({ tag19: state.opp.tag19 }),
    RESET_OPP (state) {
      if (state.opp.modified) {
        state.opp.reset(state.opp.changed())
      }
    },
    NEW_DRAFT: (state) => {
      const draft = {
        format: 'news',
        title: state.opp.get('title'),
        contact: state.opp.contact,
        description: state.opp.title,
        source: state.opp
      }
      state.newsDraft = new Draft(draft)
    },
    // Search
    NEW_QUERY: (state) => { state.query = new OppList() }
  },
  actions: {

    // Show or hide the available opps index
    setIndexVisibility({ state }, { to }) {
      // console.log('setIndexVisibility', to.name)
      const isIndex = to.name === 'opps-featured'
      const isBrowsing = state.showIndex && ['opps-details', 'opps-edit', 'opps-news-draft'].includes(to.name)
      state.showIndex = isIndex || isBrowsing
    },

    // Fetch query from server
    fetchQuery({ state }) {
      // console.log('fetchQuery')
      if (state.query.q) {
        return state.query.fetch()
      }
    },

    // Get opp listing
    fetchOpps({ dispatch, state }, { listing }) {
      // console.log('opps/fetchOpps', listing)
      // Initialize listing
      state[listing].set('current_page', 1)
      // Fetch opps
      return dispatch('fetchCollection', state[listing], { root: true })
    },

    // Get more opp listings
    fetchMoreOpps({ dispatch, commit, state }, { listing }) {
      // console.log('opps/fetchMoreOpps', listing)
      commit('START_LOADING_MORE_OPPS')
      let moreOpps = new OppList([], {}, {
        listing: state[listing].get('listing'),
        current_page: state[listing].get('current_page') + 1
      })
      return dispatch('fetchCollection', moreOpps, { root: true }).then(() => {
        state[listing].add(moreOpps.getModels())
        state[listing].set('current_page', moreOpps.get('current_page'))
        commit('STOP_LOADING_MORE_OPPS')
      })
    },

    // Get an opp from a listing index
    selectOpp({ state }, { listing, index = 0 }) {
      // console.log('opps/selectOpp', index)
      if (state[listing].length > 0) {
        state.opp = state[listing].getModels()[index]
        state[listing].selected = index + 1
      }
    },

    // Get an opp from its tag19
    fetchOpp({ commit, dispatch, state }, tag19) {
      // console.log('opps/fetchOpp', tag19)
      // Clear any existing opps
      commit('NEW_OPP')
      // Fetch given opp
      if (tag19) {
        state.opp.set({ tag19 })
        return dispatch('fetchModel', state.opp, { root: true }).then(() => {
          dispatch('fetchOppSource')
          commit('NEW_DRAFT')
        })
      }
    },

    // Get the opp source
    fetchOppSource({ dispatch, state }) {
      // console.log('opps/fetchOppSource')
      const tag19 = state.opp.get('tag19')
      state.oppSource.set({ tag19 })
      return dispatch('fetchModel', state.oppSource, { root: true })
    },

    // Save the current opp
    saveOpp({ dispatch, state }) {
      // console.log('opps/saveOpp')
      if (state.opp.isNew()) {
        return dispatch('createOpp')
      } else {
        return dispatch('saveModel', state.opp, { root: true })
      }
    },

    // Send a recommendation
    sendRecommendation({ commit, dispatch, state }) {
      // console.log('opps/sendRecommendation')
      dispatch('saveModel', state.recommendation, { root: true }).then(() => {
        commit('dialogClose', 'recommend-opp', { root: true })
      })
    },

    // Create a new opp
    createOpp({ dispatch, state }) {
      // console.log('opps/createOpp')
      return dispatch('saveModel', state.opp, { root: true })
    },

    // Send the opp to another stage
    sendOpp({ dispatch, state }, stage) {
      // console.log('opps/sendOpp', stage)
      state.opp.set({ stage })
      return dispatch('saveModel', state.opp, { root: true }).then(() => {
        // Remove from the availble listing
        state.available.remove({ tag19: state.opp.get('tag19') })
        // Select the next opp at the top
        // TODO ... this is janky, the page needs to be redirected to the next URL
        dispatch('selectOpp', { listing: 'available', index: state.available.selected })
      })
    },

    republishOpp({ dispatch, state }) {
      // console.log('opps/republishOpp')
      state.opp.set({ published_at: moment().format() })
      return dispatch('saveModel', state.opp, { root: true })
    },

    // Send the opp to retirement
    retireAssignment({ commit, dispatch, state, rootState }) {
      // console.log('opps/retireAssignment')
      // Set the stage to retired
      state.opp.set({ stage: 'retired' })
      return dispatch('saveModel', state.opp, { root: true }).then(() => {
        const tag19 = state.opp.get('tag19')
        // Remove from current listings
        // TODO ... this is better handled by looking at the opp's collections
        state.proposed.remove({ tag19 })
        state.assigned.remove({ tag19 })
        // Remove from dashboard listing
        if (rootState.dashboard.currentAssignments) {
          rootState.dashboard.currentAssignments.remove({ tag19 })
        }
        // Hide the dialog
        commit('dialogClose', 'retire-assignment', { root: true })
      })
    },

    // Cancel an assignment
    cancelAssignment({ commit, dispatch, state }) {
      // console.log('opps/cancelAssignment')
      // Figure out the stage
      const stage = state.opp.get('stage') === 'assigned' ? 'available' : 'retired'
      // Set the stage
      state.opp.set({ stage })
      return dispatch('saveModel', state.opp, { root: true }).then(() => {
        const tag19 = state.opp.get('tag19')
        // Remove from current listings
        // TODO ... this is better handled by looking at the opp's collections
        state.assigned.remove({ tag19 })
        state.proposed.remove({ tag19 })
        // Hide the dialog
        commit('dialogClose', 'cancel-assignment', { root: true })
      })
    },

    // Cancel the initiated retirement
    cancelRetirement({ commit }) {
      // console.log('opps/cancelRetirement')
      commit('dialogClose', 'retire-assignment', { root: true })
    },

    // Fetch listings for Assignments page
    fetchAssignments ({ dispatch, state }) {
      // console.log('opps/fetchAssignments')
      // Fetch all the listings
      const listings = ['requested', 'proposed', 'assigned']
      listings.forEach((listing) => {
        dispatch('fetchCollection', state[listing], { root: true })
      })
    },

    // Assign an opportunity to a requester
    assignOpp({ dispatch }, { opp, request_id }) {
      // let opp = state.requested.find({ opp.get('tag19) })
      if (opp) {
        // We default to a dash instead of null or false because
        // neither of those values is consistently handled by the
        // gateway interfaces
        let assignment_slug = '-'
        let requests = []
        // Build requests
        requests = opp.requests.map(request => {
          let requestAssigned = !request.assigned && (request.id == request_id)
          request.assigned = requestAssigned
          if (requestAssigned) {
            assignment_slug = request.requester_slug
          }
          return request
        })
        opp.set({ requests, assignment_slug })
        // Save opp
        dispatch('saveModel', opp, { root: true })
      }
    },

    // Approve a proposed opportunity
    confirmOpp({ dispatch, state }, opp) {
      // console.log('opps/confirmOpp', opp)
      let proposedOpp = state.proposed.find({ tag19: opp.get('tag19') })
      opp.set({ stage: 'assigned' })
      dispatch('saveModel', opp, { root: true }).then(() => {
        if (proposedOpp) {
          state.proposed.remove({ tag19: proposedOpp.get('tag19') })
          state.assigned.add(proposedOpp)
          state.assigned.reverseSort('published_at')
        }
      })
    },

    // Create a draft for an opp
    createDraft({ dispatch }, opp) {
      // console.log('opps/createDraft', opp)
      dispatch('push', { name: 'draft-form', params: { type: 'assignment', id: opp.get('tag19') }}, { root: true })
    },

    // Save the news draft
    saveNewsDraft({ dispatch, state, rootGetters }) {
      // console.log('opps/saveNewsDraft')
      // Set byline, now that we have rootGetters
      state.newsDraft.set({ byline: rootGetters.profile.get('name') })
      // Save draft
      return dispatch('saveModel', state.newsDraft, { root: true })
    }
  }
}
