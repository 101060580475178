<template>
  <v-overlay absolute color="#edd" opacity="0.5" v-if="isLoading">
    <j-icon
      icon="star"
      color="#a03d41"
      size="2x"
      spin
    />
  </v-overlay>
</template>

<script>

export default {
  name: 'JexyLoader',
  // -------------
  // Properties ==
  // -------------
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}

</script>
