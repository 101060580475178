// ----------------
// Contact Model ==
// ----------------

import BModel from '@/models/base/BModel'

import moment from 'moment'
import { email, required, length, same, match, empty } from 'vue-mc/validation'

export default class Contact extends BModel {

  bOptions() {
    return {
      remoteModel: 'contact',
      remotePath: '/api/staff',
      identifier: 'slug'
    }
  }

  get _manifest() {
    return {
      first_name: {
        label: 'First Name',
        validation: required
      },
      last_name: {
        label: 'Last Name',
        validation: required
      },
      title: {
        label: 'Title'
      },
      position: {
        label: 'Order',
        type: 'number'
      },
      email: {
        label: 'Contact Email *',
        type: 'email',
        validation: email
      },
      website: {
        label: 'Website',
        type: 'url'
      },
      location: {
        label: 'Location'
      },
      organization: {
        label: 'Organization'
      },
      tagline: {
        label: 'Quote'
      },
      brief_bio: {
        label: 'Article Biography',
        type: 'markdown'
      },
      full_bio: {
        label: 'Full Biography',
        type: 'markdown'
      },
      day_phone: {
        label: 'Phone',
        type: 'phone'
      },
      address: {
        label: 'Mailing Address',
        type: 'textarea'
      },
      city: {
        label: 'City'
      },
      state: {
        label: 'State',
        type: ''
      },
      zip: {
        label: 'Zip'
      },
      dob: {
        label: 'Date of Birth'
      },
      gender: {
        label: 'Pronouns'
      },
      contact_notes: {
        label: 'Contact Notes',
        type: 'markdown'
      },
      privacy: {
        label: 'Share my mailing address and phone with publicists',
        type: 'switch'
      },
      newsletter: {
        label: 'Subscribe to daily newsletter',
        type: 'switch'
      },
      groups: {
        label: 'Groups',
        type: 'checkboxes',
        default: ['Staff'],
        options: [
          'Staff',
          'Editor',
          'Room 19'
        ]
      },
      room19_username: {
        label: 'Room 19 Username',
        validation: match(/^[a-zA-Z0-9_]+$/).or(empty).format('Letters, numbers, and underscores only')
        // validation: match(/^a$/)
      },
      mailbox: {
        label: 'Mailbox',
        type: 'switch'
      },
      password: {
        label: 'Password',
        type: 'password',
        validation: length(8).or(empty)
      },
      password_confirm: {
        label: 'Password (confirm)',
        type: 'password',
        validation: same('password')
      },
      // Uploads
      badge_src: {
        label: 'Profile Photo',
        type: 'cdn',
        options: {
          cropping: 'badge'
        }
      },
      badge_src_preview: String,
      // Read Only
      admin:             Boolean,
      latest_login:      Date,
      name:              String,
      initials:          String,
      slug:              String,
      latest_item:       Object,
      created_at:        Date,
      updated_at:        Date
    }
  }

  get getName() {
    return this.get('name') || `${this.get('first_name')} ${this.get('last_name')}`
  }

  get isAdmin() {
    return this.get('admin')
  }

  get isEditor() {
    return this.get('groups').includes('Editor')
  }

  isProfileFor(user) {
    return this.get('slug') == user.slug
  }

  get cdnMetadata() {
    return {
      folder: 'contacts',
      tags: [
        'upload',
        `${this.get('first_name')} ${this.get('last_name')}`,
        moment(this.get('created_at')).format('Y-M')
      ]
    }
  }
}
