<template>
  <v-dialog
    v-bind="$attrs"
    persistent
    @click:outside="handleOutsideClick"
  >
    <j-card
      :title="title"
    >
      <!-- Body -->
      <div class="dialog-body">
        <slot />
      </div>

      <!-- Default Actions -->
      <template #actions>
        <slot name="actions">
          <!-- Cancel -->
          <j-button @action="$emit('cancel')">
            Cancel
          </j-button>
        </slot>
      </template>

      <!-- Options -->
      <template #options>
        <slot name="options" />
      </template>
    </j-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'JexyDialog',
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Alert'
    }
  },
  methods: {
    handleOutsideClick() {
      if (!this.modal) { this.$emit('cancel') }
    }
  }
}
</script>

<style lang="scss">

  .dialog-body {
    @extend %j-description;
  }

</style>