// ---------------------------
// Mailbox Label List Model ==
// ---------------------------

import BCollection from './base/BCollection'
import MailboxLabel from './MailboxLabel'

export default class MailboxLabelList extends BCollection {

  bOptions() {
    return {
      model: MailboxLabel,
      remoteModel: 'labels'
    }
  }
}
