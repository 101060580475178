<template>
  <v-card
    :min-height="height"
    outlined
  >
    <!-- Card Header -->
    <v-card-title
      v-if="title"
      dense
      elevation="0"
      class="jexy-card-title-bar"
    >
      <!-- Card Title -->
      <h4
        class="card-title"
        v-html="title"
      />

      <!-- Cancel Button -->
      <v-btn
        v-if="cancel"
        x-small
        absolute
        right
        text
        @click="$emit('cancel')"
      >
        <j-icon icon="times" />
      </v-btn>

      <!-- Other Button -->
      <v-btn
        v-if="headerButtonIcon"
        x-small
        absolute
        right
        text
        @click="$emit('header-action')"
      >
        <j-icon :icon="headerButtonIcon" />
      </v-btn>
    </v-card-title>

    <!-- Unpadded Card Content -->
    <slot />

    <!-- Card Actions -->
    <v-card-actions
      v-if="showActions"
      class="card-actions"
    >
      <slot name="options" />
      <v-spacer />
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>

import { isEmpty } from 'lodash'

export default {
  name: 'JexyCard',
  // -------------
  // Properties ==
  // -------------
  props: {
    title: {
      type: String,
      default: ''
    },
    cancel: {
      type: Boolean,
      default: false
    },
    headerButtonIcon: {
      type: String,
      default: null
    },
    loader: {
      type: Boolean,
      default: true
    },
    height: {
      type: [Number, String],
      default: null
    }
  },
  // -----------
  // Computed == 
  // -----------
  computed: {
    showActions() {
      return !isEmpty(this.$slots.actions) || !isEmpty(this.$slots.options)
    }
  }
}
</script>


<style lang="scss">

.jexy-card-title-bar {
  @extend %j-background-light;
  padding: 0 16px !important;
}

.card-title {
  @extend %j-label;
} 

.card-actions {
  @extend %j-background-light;
}
</style>
