// ---------------------
// Bifrost Collection ==
// ---------------------

import { Collection } from 'vue-mc'
import Cookies from 'js-cookie'
import Vue from 'vue'
import { isNil, reverse } from 'lodash'

export default class BCollection extends Collection {

  // -------
  // Init ==
  // -------

  bOptions() {
    return {}
  }

  options() {
    return {
      ...this.bOptions()
    }
  }

  get paginationFields () {
    return {
      // Queries
      listing: '',
      query: '',
      // Pagination
      current_page: 1,
      current_rows: 20,
      // Pagination Results
      pages: 0,
      count: '',
      start: '',
      end: ''
    }
  }

  // ----------
  // Helpers ==
  // ----------

  get q () {
    return this.get('query')
  }

  set q (query) {
    this.set({ query })
  }

  get p () {
    return this.get('current_page')
  }

  set p (current_page) {
    this.set({ current_page })
  }

  reverseSort(comparator) {
    super.sort(comparator)
    Vue.set(this, 'models', reverse(this.getModels()))
  }

  // ---------
  // Client ==
  // ---------

  getDefaultHeaders() {
    const token = Cookies.get(process.env.VUE_APP_COOKIE_NAME)
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    return headers
  }

  getModelsFromResponse(response) {
    let models = response.response.data[this.getOption('remoteModel')]
    if (isNil(models) || models === '') {
      return null
    }
    return models
  }

  onFetchSuccess(response) {
    // Read in pagination
    this.set(response.response.data.pagination)
    // Call parent function
    super.onFetchSuccess(response)
  }
}