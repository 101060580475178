// ----------------
// Section Model ==
// ----------------

import BModel from '@/models/base/BModel'
import ImageList from './ImageList'
import ContactList from './ContactList'

import moment from 'moment'
import { required } from 'vue-mc/validation'

export default class Section extends BModel {

  bOptions() {
    return {
      remoteModel: 'section',
      remotePath: '/api/sections',
      identifier: 'slug'
    }
  }

  get _manifest () {
    return {
      name: {
        label: 'Name',
        validation: required
      },
      tagline: {
        label: 'Tagline'
      },
      description: {
        label: 'Description',
        type: 'textarea',
      },
      background_color: {
        label: 'Background Color',
        type: 'color',
        default: "#CCCCCC"
      },
      highlight_color: {
        label: 'Highlight Color',
        type: 'color',
        default: "#CC9900"
      },
      editor: {
        label: 'Section Editor',
        type: 'autocomplete',
        options: {
          text: 'name',
          value: 'slug',
          query: this.getStaff,
          watch: false
        }
      },
      // Associations
      images: {
        type: 'collection',
        default: new ImageList
      },
      // Uploads
      badge_src: {
        label: 'Section Badge',
        type: 'cdn'
      },
      // Read Only
      editors:     Array,
      slug:        String,
      volume_slug: String,
      volume_name: String,
      image_count: Number,
      updated_at:  Date,
      created_at:  Date
    }
  }

  routes () {
    return {
      ...super.routes(),
      addEditor: '/api/sections/{slug}/editors',
      removeEditor: '/api/sections/{slug}/editors/{editor}'
    }
  }

  async getStaff () {
    let itemList = new ContactList([], {}, { listing: 'staff', current_rows: 0 })
    await itemList.fetch()
    return itemList.getModels()
  }

  addEditor () {
    const method = this.getOption('methods.create')
    const route = this.getRoute('addEditor')
    const params = this.getRouteParameters()
    const data = { editor: this.get('editor') }
    const url = this.getURL(route, params)
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers, data }).send()
  }

  removeEditor () {
    const method = this.getOption('methods.delete')
    const route = this.getRoute('removeEditor')
    const params = this.getRouteParameters()
    const url = this.getURL(route, params)
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers }).send()
  }

  get parentInfo () {
    return {
      parent_id: this.get('slug'),
      parent_type: 'sections'
    }
  }

  get cdnMetadata() {
    return {
      folder: 'sections',
      tags: [
        'upload',
        this.get('name'),
        this.get('slug'),
        moment(this.get('created_at')).format('Y-M')
      ]
    }
  }
}
