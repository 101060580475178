// Models
import Auth from '@/models/Auth'
import Contact from '@/models/Contact'

// Vue
import router from '@/router';

// Helpers
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import { version } from '../../package'

function initialState() {
  return {
    version,
    init:    false,
    jwt:     null,
    login:   new Auth(),
    profile: new Contact(),
    payload: { },
    loginReturn: false
  }
}

export const auth = {
  state: initialState(),
  getters: {
    isLoggedIn: state => state.jwt !== null,
    isAdmin:    state => state.payload.admin,
    isEditor:   state => state.payload.groups.includes('Editor'),
    isExpiring: state => {
      const expiration = new Date(state.payload.exp * 1000)
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return expiration < tomorrow
    },
    userSlug:   state => state.payload.slug,
    jwt:        state => state.jwt,
    policy:     state => model => action => { console.log(state, model, action); return true },
    profile:    state => state.profile
  },
  actions: {
    authInit({ dispatch, state }) {
      // Don't run twice
      if (state.init) { return }
      // Do we have an existing cookie?
      const cookieJwt = Cookies.get(process.env.VUE_APP_COOKIE_NAME)
      if (cookieJwt) {
        // If so, login with it
        dispatch('setJWT', cookieJwt).then(() => {
          // Mark as initialized
          state.init = true
        })
      }
    },
    async login({ dispatch }, jwt) {
      // console.log('login', jwt)
      // Expired JWT for testing
      // jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NjUzNjA3ODQsInN1YiI6MSwiZW1haWwiOiJpYW5AaW5rMTkuY29tIiwiYWRtaW4iOmZhbHNlfQ.Kt7Z0oRymAjEcd09KEMFIP_wEhxCTB02xA4PGZZXlP8"
      // Set JWT
      dispatch('setJWT', jwt)
    },
    loginReturn({ state }, route = false) {
      // console.log('loginReturn', route)
      // Were we given a route?
      if (route) {
        // About to log in, save the route
        state.loginReturn = route
      } else {
        // Done logging in, send back to wherever
        const prev_route = state.loginReturn
        if (prev_route) {
          // Send to previous page
          state.loginReturn = false
          router.push(prev_route)
        } else {
          // Send home
          router.push('/')
        }
      }
    },
    async logout({ dispatch }) {
      // Clear JWT and forward to login
      await dispatch('setJWT', null)
      router.push({ name: 'login' })
    },
    setJWT({ state, commit }, jwt) {
      // console.log('setJWT', jwt)
      // Decode and store payload
      if (jwt) {
        try {
          // First, decode JWT to see if it's valid
          // Bad JWTs will end up in the catch block
          const payload = jwtDecode(jwt)
          const now = Math.floor(Date.now() / 1000)
          const expired = (payload.exp < now)
          if (expired) {
            // Expired JWT, reset
            commit('RESET_JWT')
          } else {
            // Set JWT annd payload in vuex
            state.jwt = jwt
            state.payload = payload
            // Set JWT in cookie
            Cookies.set(process.env.VUE_APP_COOKIE_NAME, jwt, { expires: 14 })
            // Fetch user profile
            state.profile.set('slug', state.payload.slug)
            return state.profile.fetch()
          }
        } catch (_) {
          // Bad JWT, reset
          commit('RESET_JWT')
        }
      } else {
        // Clear JWT, reset
        commit('RESET_JWT')
      }
    }
  },
  mutations: {
    RESET_JWT(state) {
      const init = initialState()
      state.jwt     = init.jwt
      state.payload = init.payload
      state.profile = init.profile
      Cookies.remove(process.env.VUE_APP_COOKIE_NAME)
    }
  }
}
