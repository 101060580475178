import Embed from '@/models/Embed'
import EmbedList from '@/models/EmbedList'
import Image from '@/models/Image'
import ImageList from '@/models/ImageList'

export default {
  namespaced: true,
  state: {
    // Models for editing
    image: new Image(),
    embed: new Embed(),
    // Listings
    embeds: new EmbedList(),
    images: new ImageList(),
    // Source
    source: false,
    // Cropping
    cropper: false,
    cropping: {},
    controls: {},
    aspectRatioNameLock: false,
    fixedWidthLock: false
  },
  getters: {
    // UI
    loading(state) {
      return state.embed.loading
        || state.embeds.loading
        || state.image.loading
        || state.images.loading
    },
    mediaCount(state) {
      const imageCount = state.images ? state.images.length : 0
      const embedCount = state.embeds ? state.embeds.length : 0
      return imageCount + embedCount
    },
    // CDN
    cdnMetadata(state) {
      if (state.source) {
        return state.source.cdnMetadata
      } else {
        return {}
      }
    }
  },
  mutations: {
    // Init
    SET_SOURCE: (state, source) => {
      state.source = source
      if (source.images) { state.images = source.images }
      if (source.embeds) { state.embeds = source.embeds }
    },
    // Embeds
    SET_EMBED: (state, embed) => state.embed = embed,
    NEW_EMBED(state) {
      // Make sure embed is related to source and that
      // it gets added to its embeds collection
      state.embed = new Embed(state.source.parentInfo, state.source.embeds)
    },
    RESET_EMBED: (state) => state.embed.reset(state.embed.changed()),
    // Images
    SET_IMAGE: (state, image) => state.image = image,
    NEW_IMAGE(state) {
      // Make sure image is related to source and that
      // it gets added to its images collection
      state.image = new Image(state.source.parentInfo, state.source.images)
    },
    RESET_IMAGE: (state) => state.image.reset(state.image.changed()),
    CROP_SETTINGS: (state, settings = {}) => {
      // Set locks if given
      state.aspectRatioNameLock = settings.aspectRatioName || false
      state.fixedWidthLock = settings.fixedWidth || false
      // Figure out settings
      const aspectRatioName = state.aspectRatioNameLock || state.image.croppingData.aspectRatioName || 'free'
      const fixedWidth = state.fixedWidthLock || state.image.croppingData.fixedWidth || 1200
      // Set controls
      state.controls = {
        aspectRatioName,
        fixedWidth
      }
    }
  },
  actions: {

    // -----------------
    // Embed Handling ==
    // -----------------

    // Edit embed
    editEmbed({ commit }, embed) {
      // console.log('embeds/editembed', embed)
      commit('SET_EMBED', embed)
    },

    // Save the embed
    saveEmbed({ commit, dispatch, state }) {
      // console.log('embeds/saveembed')
      // Save embed
      return dispatch('saveModel', state.embed, { root: true }).then(() => {
        // If the source has a body, add a space to
        // trigger a re-render
        // TODO: We can do better
        if (state.source.get('body')) {
          state.source.set('body', `${state.source.body} `)
        }
        // Reset things on success
        commit('NEW_EMBED')
      })
    },

    // Delete the embed
    deleteEmbed({ dispatch, state }) {
      // console.log('embeds/deleteembed')
      return dispatch('deleteModel', state.embed, { root: true })
    },

    // -----------------
    // Media Handling ==
    // -----------------

    // Init image cropper
    initCropper({ state, dispatch }, cropper) {
      // console.log('initCropper', cropper)
      state.cropper = cropper
      dispatch('updateCroppingData')
    },

    // Open image cropper 
    openCropper({ state, commit, dispatch }) {
      // console.log('openCropper')
      // Set cropping for image
      state.cropping = {
        ...state.image.croppingData,
        ...state.controls
      }
      // Update aspect ratio
      dispatch('updateCroppingData')
      // Show cropper
      commit('dialogOpen', 'cropper', { root: true })
    },

    // Update the aspect ratio
    updateCroppingData({ state }) {
      console.log('updateCroppingDataIn')
      if (!state.cropper) return
      
      const aspectRatio = state.image.aspectRatioValue(state.controls.aspectRatioName)
      state.cropper.setAspectRatio(aspectRatio)
      state.cropper.setData(state.cropping)
    },

    // Set cropping data
    updateCropper({ state, dispatch }) {
      // console.log('updateCropper')
      if (!state.cropper) return

      state.cropping = {
        ...state.cropper.getData(true),
        ...state.controls
      }
      dispatch('updateCroppingData')
    },

    // Close the cropper
    closeCropper({ state, commit }) {
      // console.log('closeCropper')
      // Reset locks
      state.aspectRatioNameLock = false
      state.fixedWidthLock = false
      // Hide cropping window
      commit('dialogClose', 'cropper', { root: true })
    },

    // Open Cloudinary widget
    openUploader({ state, dispatch }, options={}) {
      // console.log('openUploader', options)
      // Get processor
      let processor = options.processor
      if (processor) {
        delete options.processor
      } else {
        // Handle missing processors
        console.error('Missing processor for image uploader')
        processor = (upload) => console.log('Uploaded file:', upload)
      }
      // Disable cropping
      let croppingOptions = { cropping: false }
      // Set up defaults
      const primary = '#a03d41'
      const primaryDark = '#4A1C1E'
      const background = '#F9F9F9'
      const uploadSignature = (callback, params) => dispatch('signUpload', { callback, params })
      const uploaderDefaults = {
        // API
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        apiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
        googleApiKey: process.env.VUE_APP_GOOGLE_SEARCH_API_KEY,
        uploadSignature,
        // Styling
        theme: 'minimal',
        styles:{
          palette: {
            windowBorder: primary,
            tabIcon: primary,
            link:  primary,
            action:  primary,
            inactiveTabIcon: primaryDark,
            textDark: primaryDark,
            inProgress: primary,
            sourceBg: background
          }
        },
        // Configuration
        sources: ['local', 'image_search', 'url', 'camera'],
        resourceType: 'image',
        multiple: false,
        // Metadata
        folder: 'unsourced',
        tags: [],
        context: {}
      }
      // Configure the Cloudinary widget
      // eslint-disable-next-line
      state.uploader = cloudinary.openUploadWidget({
          ...uploaderDefaults,
          ...options,
          ...croppingOptions
        }, (error, { event, info }) => {
          if (error) {
            // this.$store.dispatch('error', error)
            console.error('Upload error', error)
          } else if (event === 'success') {
            const upload = {
              cdn_src: `image/upload/${info.path}#${info.signature}`,
              title: info.original_filename,
              width: info.width,
              height: info.height,
              bytes: info.bytes
            }
            processor(upload)
          }
        }
      )
    },

    // Sign Cloudinary upload
    async signUpload({ state }, { callback, params }) {
      // console.log('signUpload', callback, params)
      const signature = await state.image.signUpload(params)
      callback(signature)
    },

    // Add Cloudinary upload
    addUpload({ state, dispatch }, upload) {
      // console.log('addUpload', upload)
      let uploadedImage = new Image ({
        ...state.source.parentInfo,
        ...upload
      })
      dispatch('saveModel', uploadedImage, { root: true }).then(() => {
        state.images.add(uploadedImage)
      })
    },

    // Edit image
    editImage({ commit }, image) {
      // console.log('images/editImage', image)
      commit('SET_IMAGE', image)
    },

    // Save the image
    async saveImage({ commit, dispatch, state }) {
      // console.log('images/saveImage')
      // Keep track of changed featured image
      const featuredSelected = state.image.changed().includes('featured') || state.image.get('featured')
      // Add default title to new images
      if (state.image.isNew() && !state.image.get('title')) {
        const title = state.image.graphic.name
        state.image.set({ title })
      }
      // Save image
      dispatch('saveModel', state.image, { root: true }).then(() => {
        // If the source has a body, add a space to
        // trigger a re-render
        // TODO: We can do better
        if (state.source.get('body')) {
          state.source.set('body', `${state.source.body} `)
        }
        // If this image was selected as a feature
        // image, reload the whole list and reassign
        // the source badge
        if (featuredSelected) {
          state.source.images.fetch()
          state.source.fetch()
        }
        // Reset things on success
        commit('NEW_IMAGE')
      })
    },

    // Delete the image
    async deleteImage({ dispatch, state }) {
      // console.log('images/deleteImage')
      dispatch('deleteModel', state.image, { root: true })
    }
  }
}
