import Comment from '@/models/Comment'
import CommentList from '@/models/CommentList'

export default {
  namespaced: true,
  state: {
    // UI
    commentFormVisible: false,
    commentDialogVisible: false,
    // Individual Comment
    comment: new Comment(),
    // Listing
    comments: new CommentList(),
    // Source
    source: false
  },
  getters: {
    // UI
    loading(state) {
      return state.comment.loading
        || state.comments.loading
    }
  },
  mutations: {
    // UI
    SHOW_COMMENT_FORM: (state) => state.commentFormVisible = true,
    HIDE_COMMENT_FORM: (state) => state.commentFormVisible = false,
    SHOW_COMMENT_DIALOG: (state) => state.commentDialogVisible = true,
    HIDE_COMMENT_DIALOG: (state) => state.commentDialogVisible = false,
    // Init
    SET_SOURCE: (state, source) => state.source = source,
    NEW_COMMENT: (state) => state.comment = new Comment(state.source.parentInfo, state.comments)
  },
  actions: {

    // Show comment form (in Comment Manager)
    showCommentForm({ commit }) {
      // console.log('comments/showCommentForm')
      commit('NEW_COMMENT')
      commit('SHOW_COMMENT_FORM')
    },

    // Show comment dialog
    showCommentDialog({ commit }) {
      // console.log('comments/showCommentDialog')
      commit('NEW_COMMENT')
      commit('SHOW_COMMENT_DIALOG')
    },

    // Fetch comments for the source
    async fetchComments({ commit, dispatch, state }, source) {
      // console.log('comments/fetchComments', source)
      // Set source if given
      if (source) {
        commit('SET_SOURCE', source)
        commit('NEW_COMMENT')
      }
      // No new comments for new sources
      if (source.isNew()) { return }
      // Set parent information
      state.comments.set(state.source.parentInfo)
      // Fetch comments
      dispatch('fetchCollection', state.comments, { root: true })
    },

    // Save comment
    async saveComment({ commit, dispatch, state }) {
      // console.log('comments/saveComment')
      if (state.comment.changed()) {
        dispatch('saveModel', state.comment, { root: true }).then(() => {
          // Sort comments by reversed date to bring new comment to top
          state.comments.reverseSort('created_at')
          // Increment counter
          // TODO: Only when adding a comment
          state.source.set('comment_count', state.source.get('comment_count') + 1) 
          // Reset comment
          commit('NEW_COMMENT')
          // Done with form or dialog
          commit('HIDE_COMMENT_FORM')
          commit('HIDE_COMMENT_DIALOG')
        })
      }
    }
  }
}
