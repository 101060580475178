// Elements
import Alert from './elements/Alert'
import Avatar from './elements/Avatar'
import Button from './elements/Button'
import Card from './elements/Card'
import CardText from './elements/CardText'
import Comment from './elements/Comment'
import Counter from './elements/Counter'
import Divider from './elements/Divider'
import Dialog from './elements/Dialog'
import Graphic from './elements/Graphic'
import Heading from './elements/Heading'
import Icon from './elements/Icon'
import Label from './elements/Label'
import Listing from './elements/Listing'
import Markdown from './elements/Markdown'
import Menu from './elements/Menu'
import MenuItem from './elements/MenuItem'
import Search from './elements/Search'
import Tab from './elements/Tab'
import Table from './elements/Table'
import Tile from './elements/Tile'

// Forms
import Actions from './forms/Actions'
import Control from './forms/Control'
import Display from './forms/Display'
import Form from './forms/Form'
import Input from './forms/Input'

// Layout
import ViewPage from './layout/Content'

import { kebabCase } from 'lodash'

export default Vue => {
  const components = {
    // Forms
    "j-actions":    Actions,
    "j-control":    Control,
    "j-display":    Display,
    "j-form":       Form,
    "j-input":      Input,
    // Elements
    "j-alert":      Alert,
    "j-avatar":     Avatar,
    "j-button":     Button,
    "j-card":       Card,
    "j-card-text":  CardText,
    "j-counter":    Counter,
    "j-dialog":     Dialog,
    "j-divider":    Divider,
    "j-graphic":    Graphic,
    "j-heading":    Heading,
    "j-icon":       Icon,
    "j-label":      Label,
    "j-markdown":   Markdown,
    "j-menu":       Menu,
    "j-menu-item":  MenuItem,
    "j-search":     Search,
    "j-tab":        Tab,
    "j-table":      Table,
    // Layout
    "j-comment":    Comment,
    "j-listing":    Listing,
    "j-tile":       Tile,
    "j-page":       ViewPage,
  };

  Object.keys(components).forEach(key => {
    Vue.component(kebabCase(key), components[key])
  })
}
