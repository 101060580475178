<template>

  <!-- Standard Form Actions -->
  <div class="form-actions">

    <!-- Reset -->
    <j-button
      v-if="!record.saving && record.modified"
      label="Reset"
      type="option"
      @action="handleConfirmReset"
    />

    <!-- Cancel -->
    <j-button
      v-else-if="!record.saving"
      label="Cancel"
      type="option"
      @action="handleCancel"
    />

    <!-- Save -->
    <j-button
      :label="record.isNew() ? 'Create' : 'Save'"
      :processing="record.saving"
      :disabled="record.pristine"
      class="ml-2"
      @action="handleSave"
    />

    <!-- Confirm Reset -->
    <j-dialog
      dialog-name="confirm-reset"
      :value="$dialog.status('confirm-reset')"
      title="Confirm reset..."
      width="50%"
      class="confirm-reset-dialog"
      @cancel="handleCloseDialog"
    >
      <!-- Body -->
      <j-card-text class="mt-4">
        <p class="j-body">Discard all changes?</p>
      </j-card-text>

      <!-- Options -->
      <template #options>
        <!-- Cancel -->
        <j-button
          label="Cancel"
          type="option"
          @action="handleCloseDialog"
        />
      </template>

      <!-- Actions -->
      <template #actions>
        <!-- Delete -->
        <j-button
          label="Reset"
          type="danger"
          @action="handleReset"
        />
      </template>
    </j-dialog>
  </div>
</template>

<script>

export default {
  name: 'FormActions',
  // -------------
  // Properties ==
  // -------------
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    handleConfirmReset() {
      this.$dialog.open('confirm-reset')
    },
    handleCloseDialog() {
      this.$dialog.close('confirm-reset')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleSave() {
      this.$emit('save')
    },
    handleReset() {
      this.$dialog.close('confirm-reset')
      this.record.reset()
      this.$emit('reset')
    }
  }
}
</script>
