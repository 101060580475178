import moment from 'moment'
import { capitalize, truncate } from 'lodash'

export default {
  install(Vue) {
    Vue.filter('shortTime', (value, utc) => formatMoment(value, 'HH:mm A', utc))

    Vue.filter('shortDate', (value, utc) => formatMoment(value, 'D MMM YYYY', utc))

    Vue.filter('longDate', (value, utc) => formatMoment(value, 'D MMMM YYYY HH:mm:ss', utc))

    Vue.filter('weekdayDate', (value, utc) => formatMoment(value, 'dddd MMMM D', utc))

    Vue.filter('shortMonth', (value, utc) => formatMoment(value, 'MMM YYYY', utc))

    Vue.filter('longMonth', (value, utc) => formatMoment(value, 'MMMM YYYY', utc))

    Vue.filter('percent', (value, decimals) => formatPercent(value, decimals))

    Vue.filter('truncate', (value, letters = 30) => truncate(value, { length: letters, separator: /,? +/ }))

    Vue.filter('capitalize', (value) => capitalize(value))
  }
}

function formatMoment(value, format, utc = false) {
  if (value) {
    // Is it a Unix timestamp?
    if (typeof value === 'number') {
      return moment.unix(value).format(format)
    } else {
      // Are we using UTC?
      if (utc) {
        return moment.utc(value).format(format)
      } else {
        return moment(value).format(format)
      }
    }
  } else {
    // No value return blank
    return ''
  }
}

function formatPercent(value, decimals = 2) {
  if (!value) value = 0;
  value = value * 100;
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + ' %';
}
