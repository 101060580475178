import Draft from '@/models/Draft'
import Opp from '@/models/Opp'
import OppList from '@/models/OppList'
import Section from '@/models/Section'
import SectionList from '@/models/SectionList'

export default {
  namespaced: true,
  state: {
    // UI
    assignmentsTab: 'assignments',
    // Individual Item
    draft: new Draft(),
    // Sources
    assignments: new OppList,
    magazine: new SectionList,
    columns: new SectionList,
    news: new SectionList
  },
  getters: {
    // UI
    loading (state) {
      return state.assignments.loading
        || state.magazine.loading
        || state.columns.loading
        || state.news.loading
        || state.draft.loading
    },
    // Drafts
    draftSection (state) {
      if (state.draft.sourceType == 'section') {
        return state.draft.get('source')
      } else if (state.draft.sourceType == 'assignment') {
        const slug = state.draft.get('format')
        return state.magazine.find({ slug })
      } else {
        return new Section
      }
    }
  },
  mutations: {
    // Init
    RESET_DRAFT(state, byline) { state.draft = new Draft({ byline }) },
    // UI
    SET_ASSIGNMENTS_TAB(state, tab) { state.assignmentsTab = tab }
  },
  actions: {

    // Set up the draft context
    newContext ({ rootGetters, dispatch, state }) {
      // Get sources
      const userSlug = rootGetters.profile.get('slug')
      // Get assignments
      state.assignments.set({ listing: `assigned-to-${userSlug}` })
      dispatch('fetchCollection', state.assignments, { root: true }).then(() => {
        if (state.assignments.length == 0) {
          state.assignmentsTab = 'magazine'
        }
      })
      // Get magazine sections
      state.magazine.set({ listing: 'magazine' })
      dispatch('fetchCollection', state.magazine, { root: true })
      // Get postable columns
      state.columns.set({ listing: `columns-for-${userSlug}` })
      dispatch('fetchCollection', state.columns, { root: true })
      // Get news sections
      state.news.set({ listing: 'news' })
      dispatch('fetchCollection', state.news, { root: true })
    },

    // Set up a new draft
    newDraft ({ rootGetters, dispatch, commit }, params) {
      // console.log('drafts/newDraft', params)
      // Set up new draft
      commit('RESET_DRAFT', rootGetters.profile.get('name'))
      // Set assignment if we have a source
      dispatch('setDraftSource', { type: params.type, id: params.id })
    },

    // Set the draft's source
    setDraftSource ({ dispatch, state }, { type, id }) {
      // console.log('drafts/setDraftSource', type, id)
      // Reset draft to wipe + set byline
      dispatch('resetDraft')
      // Prepare source
      if (type == 'assignment') {
        let source = new Opp({ tag19: id })
        state.draft.set({ source })
      } else {
        let source = new Section({ slug: id })
        state.draft.set({ source })
      }
      // Fetch source
      state.draft.source.fetch().then(() => {
        // Set format for sections
        if (type == 'section') {
          state.draft.set('section_slug', id)
          if (state.draft.get('source').get('volume_slug') == 'magazine') {
            state.draft.set('format', id)
          } else if (state.draft.get('source').get('volume_slug') == 'news') {
            state.draft.set('format', 'news')
          }
        } else {
          state.draft.set('format', state.draft.source.section.slug)
          state.draft.set('contact', state.draft.source.contact)
          state.draft.set('released_on', state.draft.source.released_on)
        }
      })
    },

    // Reset the draft
    resetDraft ({ rootGetters, commit }) {
      // console.log('drafts/resetDraft')
      const byline = rootGetters.profile.get('name')
      commit('RESET_DRAFT', byline)
    },

    // Save the draft
    saveDraft ({ state }) {
      return state.draft.save()
    }
  }
}
