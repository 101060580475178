// -----------------------
// Recommendation Model ==
// -----------------------

import BModel from '@/models/base/BModel'
import ContactList from './ContactList'

import { required } from 'vue-mc/validation'

export default class Recommendation extends BModel {

  bOptions() {
    return {
      remoteModel: 'recommendation',
      identifier: 'tag19'
    }
  }

  get _name () {
    return 'recommendation'
  }

  get _manifest () {
    return {
      recommended_slug: {
        label: 'Recommend For',
        type: 'autocomplete',
        validation: required,
        options: {
          text: 'name',
          value: 'slug',
          query: this.getStaff
        }
      },
      message: {
        label: 'Message (optional)',
        type: 'textarea',
      },
      assign: {
        label: 'Make assignment',
        type: 'switch',
      },
      // Read Only
      tag19: String
    }
  }

  routes () {
    return {
      save: '/api/opps/{tag19}?recommend=1'
    }
  }

  async getStaff(query) {
    if (query) {
      let itemList = new ContactList([], {}, {
        listing: 'staff-dropdown',
        query: query ? query : '',
        current_rows: 0,
      })
      await itemList.fetch()
      return itemList.getModels()
    } else {
      return [{
        name: '',
        slug: ''
      }]
    }
  }
}
