// --------------
// Image Model ==
// --------------

import BModel from '@/models/base/BModel'
import { required } from 'vue-mc/validation'

export default class Image extends BModel {

  bOptions() {
    return {
      remoteModel: 'image',
      identifier: 'tag19'
    }
  }

  get _manifest() {
    return {
      title: {
        label: 'Name',
        validation: required
      },
      credit: {
        label: 'Credit'
      },
      caption: {
        label: 'Caption',
        type: 'textarea'
      },
      align: {
        label: 'Alignment',
        type: 'select',
        options: ['fill', 'center', 'left', 'right'],
        default: 'fill',
        validation: required
      },
      featured: {
        label: 'Featured image',
        type: 'switch'
      },
      // Uploads
      cdn_src: {
        label: 'CDN Graphic',
        type: 'cdn',
        options: {
          cropping: 'local'
        }
      },
      cropping: {
        label: 'Cropping'
      },
      width: { label: 'Width' },
      height: { label: 'Height' },
      bytes: { label: 'Bytes' },
      // Read Only
      original_src:    String,
      cdn_src_preview: String,
      tag19:           String,
      updated_at:      Date,
      created_at:      Date,
      parent_type:     String,
      parent_id:       Number
    }
  }

  routes() {
    return {
      fetch:  '/api/images/{tag19}',
      create: '/api/{parent_type}/{parent_id}/images',
      save:   '/api/images/{tag19}',
      delete: '/api/images/{tag19}',
      sign:   '/api/images/signature'
    }
  }

  signUpload(signature) {
    const method = this.getOption('methods.create')
    const route = this.getRoute('sign')
    const params = this.getRouteParameters()
    const url = this.getURL(route, params)
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers, data: { signature } })
      .send()
      .then(({ response }) => response.data.signature)
  }

  aspectRatioValue (aspectRatioName) {
    switch (aspectRatioName) {
      case 'widescreen':
        return 16 / 9
      case 'landscape':
        return 4 / 3
      case 'square':
        return 1
      case 'portrait':
        return 3 / 4
      default:
        return 0
    }
  }

  get src() {
    return this.get('cdn_src') || this.get('original_src')
  }

  get croppingData() {
    // Needs to be converted from strings, or the
    // cropping lib will fail to do its job
    const cropping = this.get('cropping')
    if (cropping) {
      let converted = {}
      for (let field in cropping) {
        if (field === 'aspectRatioName') {
          converted['aspectRatio'] = this.aspectRatioValue(cropping[field])
          converted['aspectRatioName'] = cropping[field]
        } else if (field !== 'aspectRatio') {
          converted[field] = parseInt(cropping[field])
        }
      }
      return converted
    } else {
      return {}
    }
  }
}
