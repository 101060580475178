import Vue from 'vue'
import Vuex from 'vuex'

import { alerts } from './alerts'
import { auth } from './auth'
import { dialogs } from './dialogs'
import { models } from './models'
import { route } from './route'
import announcements from './modules/announcements'
import articles from './modules/articles'
import comments from './modules/comments'
import dashboard from './modules/dashboard'
import drafts from './modules/drafts'
import media from './modules/media'
import mailbox from './modules/mailbox'
import opps from './modules/opps'
import publicists from './modules/publicists'
import sections from './modules/sections'
import staff from './modules/staff'
import wiki from './modules/wiki'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // System
    alerts,
    auth,
    dialogs,
    models,
    route,
    // Modules
    announcements,
    articles,
    comments,
    dashboard,
    drafts,
    media,
    mailbox,
    opps,
    publicists,
    sections,
    staff,
    wiki
  }
})
