<template>
  <v-row class="jexy-heading">
    <v-col>
      <h3 class="heading">
        <!-- Header Text -->
        <span v-html="heading" />

        <!-- Item Count -->
        <j-label
          v-if="count"
          :title="count"
          small
          class="ml-2 mt-n1"
        />
      </h3>

      <!-- Subhead -->
      <p
        v-if="subhead"
        class="subhead my-2"
        v-html="subhead"
      />
    </v-col>

    <!-- Actions -->
    <v-col
      v-if="$slots.actions"
      sm="6"
      class="heading-actions d-flex align-center justify-end"
    >
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'JexyHeading',
  // -------------
  // Properties ==
  // -------------
  props: {
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    count: {
      type: [Number, Boolean],
      default: 0
    }
  }
}
</script>

<style lang="scss">

.jexy-heading {
  height: 48px;
  
  .heading {
    @extend %j-body-large;
    font-weight: normal;
  }

  .heading-actions {
    .search-input {
      transform: scale(0.75) translateX(15%);
      position: relative;
      top: -10px;
    }
  }
} 

</style>
