import store from '@/store/'

export default {
    install: (Vue) => {
        Vue.prototype.$dialog = {
            init: (dialog, state=false) => {
                if (state) {
                    store.commit('dialogOpen', dialog)
                } else {
                    store.commit('dialogClose', dialog)
                }
            },
            status: (dialog) => {
                return store.getters['dialogStatus'](dialog)
            },
            open: (dialog) => {
                store.commit('dialogOpen', dialog)
            },
            close: (dialog) => {
                store.commit('dialogClose', dialog)
            }
        }
    }
}