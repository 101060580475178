// --------------
// Embed Model ==
// --------------

import BModel from '@/models/base/BModel'
import { required } from 'vue-mc/validation'

export default class Embed extends BModel {

  bOptions() {
    return {
      remoteModel: 'embed',
      identifier: 'tag19'
    }
  }

  get _manifest() {
    return {
      title: {
        label: 'Name',
        validation: required
      },
      source: {
        label: 'Source',
        validation: required
      },
      caption: {
        label: 'Caption',
        type: 'textarea'
      },
      align: {
        label: 'Alignment',
        type: 'select',
        options: ['fill', 'left', 'right'],
        default: 'fill',
        validation: required
      },
      height: {
        label: 'Height',
        type: 'number',
        validation: required
      },
      featured: {
        label: 'Featured Media',
        type: 'switch'
      },
      source_code: {
        label: 'Embed Code',
        type: 'textarea'
      },
      embed_type: {
        label: 'Source'
      },
      // Read Only
      tag19:           String,
      updated_at:      Date,
      created_at:      Date,
      parent_type:     String,
      parent_id:       Number
    }
  }

  routes() {
    return {
      fetch:  '/api/embeds/{tag19}',
      create: '/api/{parent_type}/{parent_id}/embeds',
      save:   '/api/embeds/{tag19}',
      delete: '/api/embeds/{tag19}'
    }
  }
}
