import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/'

// Vuetify
import vuetify from '@/plugins/vuetify'

// Cloudinary
import './plugins/cloudinary.js'

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'
library.add(far, fas, fab)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)

// Vue Social Sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

// Filters
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)
import JexyFilters from '@/plugins/filters.js'
Vue.use(JexyFilters)

// Dialog
import Dialog from '@/plugins/dialog.js'
Vue.use(Dialog)

// Global Components
import globalComponents from '@/components'
globalComponents(Vue)

// User
import User from '@/models/User'
Vue.prototype.$user = new User()

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  vuetify,
  store
}).$mount('#app')
