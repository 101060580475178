// -------------------
// Embed List Model ==
// -------------------

import BCollection from './base/BCollection'
import Embed from './Embed'

export default class EmbedList extends BCollection {

  bOptions() {
    return {
      model: Embed,
      remoteModel: 'embeds'
    }
  }

  routes() {
    return {
      fetch: '/api/{parent_type}/{parent_id}/embeds'
    }
  }
}