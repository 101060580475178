<template>
  <!-- Column -->
  <v-col
    v-if="useColumns"
    :cols="cols"
    :sm="sm"
    class="form-group pt-1 pb-0"
  >
    <j-input
      v-bind="$attrs"
      @change="$emit('change')"
    />
  </v-col>
  <!-- Plain -->
  <div
    v-else
    class="form-group pt-1 pb-0"
  >
    <j-input
      v-bind="$attrs"
      @change="$emit('change')"
    />
  </div>
</template>

<script>

import { isEmpty } from 'lodash'

export default {
  inheritAttrs: false,
  props: {
    model: {
      type: Object,
      default: null
    },
    cols: {
      type: [String, Number],
      default: 12
    },
    sm: {
      type: [String, Number],
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    display () {
      return this.$attrs.display || !isEmpty(this.$slots.default)
    },
    useColumns () {
      return this.cols || this.sm
    },
    inputModel() {
      return this.model || this.$parent.model
    }
  }
}
</script>


<style lang="scss">

.j-input {

  label {
    font-family: $heading-font !important;
    font-weight: bold;
  }

  input, textarea, select, .v-select__slot {
    font-family: $body-font !important;
  }
} 

.j-display {

  .v-input__slot {
    display: block;
  }

  label {
    font-family: $heading-font !important;
    font-weight: bold;
    font-size: 75%;
  }

  .v-input__slot {
    font-family: $body-font !important;
  }
} 

</style>
