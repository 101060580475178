import BModel from '@/models/base/BModel'
import {
  required
} from 'vue-mc/validation'

export default class WikiUploader extends BModel {
  _name() {
    return 'wiki_uploader'
  }

  manifest() {
    return {
      uploadFiles: {
        label: 'Add Files',
        type: 'multifile',
        validation: { required }
      }
    }
  }
}
