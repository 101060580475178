// ----------------
// Bifrost Field ==
// ----------------

import { startCase, isEmpty } from 'lodash'

export default class BField {

  // ----------
  // Factory ==
  // ----------

  static make (field, config, model) {
    switch (config.type) {
      case 'autocomplete':
        return new AutocompleteField (field, config, model)
      case 'cdn':
        return new CdnField (field, config, model)
      case 'checkboxes':
      case 'select':
        return new SelectField (field, config, model)
      case 'tags':
        return new TagsField (field, config, model)
      case 'markdown':
      case 'textarea':
        return new TextareaField (field, config, model)
      default:
        return new BField (field, config, model)
    }
  }

  // -------
  // Init ==
  // -------

  constructor (name, config, model) {
    this._name = name
    this._config = {
      // Default Configuration
      label: startCase(name),
      type: 'text',
      options: {},
      default: '',
      validation: null,
      mutation: null,
      readOnly: false,
      local: false,
      // Model Configuration
      ...config
    }
    // Make identifiers read-only
    const identifier = model.bOptions().identifier || 'id'
    if (name == identifier) this._config.readOnly = true
    // Typed values
    if (config.name) {
      // Typed values are always local
      this._config.local = true
      // Override default for objects and arrays
      switch (config.name) {
        case 'Object':
          this._config.type = 'object'
          this._config.default = () => ({})
          break
        case 'Array':
          this._config.type = 'array'
          this._config.default = () => ([])
          break
      }
    }
    this._model = model
  }

  // ----------
  // Utility ==
  // ----------

  evaluateProperty(property) {
    if (typeof property === 'function') {
      // Execute properties that are functions
      return property(this.model)
    } else {
      // Return propeties that are values
      return property
    }
  }

  // ----------------------
  // Instance Properties ==
  // ----------------------

  get name () {
    return this._name
  }

  get model () {
    return this._model
  }

  get config () {
    return this._config
  }

  // --------------------
  // Config Properties ==
  // --------------------

  get type () {
    return this.evaluateProperty(this.config.type)
  }

  get label () {
    return this.evaluateProperty(this.config.label)
  }

  get hint () {
    return this.evaluateProperty(this.config.hint)
  }

  get placeholder () {
    return this.evaluateProperty(this.config.placeholder)
  }

  get options () {
    return this.evaluateProperty(this.config.options)
  }

  get default () {
    return this.evaluateProperty(this.config.default)
  }

  get validation () {
    return this.config.validation
  }

  get mutation () {
    return this.evaluateProperty(this.config.mutation)
  }

  get readOnly () {
    return this.evaluateProperty(this.config.readOnly)
  }

  get local () {
    return this.evaluateProperty(this.config.local)
  }

  // -------------------
  // Model Properties ==
  // -------------------

  get value () {
    return this.model.get(this.name)
  }

  get invalid () {
    return !isEmpty(this.model.errors[this.name])
  }

  get errors () {
    return this.model.errors[this.name] || null
  }

  // ------------------------
  // Calculated Properties ==
  // ------------------------

  get id () {
    const remoteModel = this.model.remoteModel
    const identifier = this.model.identifier() || 'new'
    return `${remoteModel}-${identifier}-${this.name}`
  }

  get display () {
    return this.model.get(this.name)

    // let displayValue = ''
    // switch (this.type) {
    //   case 'file':
    //     // Is there an attached file?
    //     if (fieldValue instanceof File) {
    //       // Is it an image?
    //       if (fieldValue.type.startsWith('image')) {
    //         displayValue = URL.createObjectURL(fieldValue);
    //       } else {
    //         // Not an image
    //         displayValue = false
    //       }
    //     } else
    //     // Is there some sort of parsing function?
    //     if (typeof this.options.preview === 'function') {
    //       try {
    //         displayValue = this.options.preview(this)
    //       } catch (error) {
    //         // Don't show if the preview function fails
    //         displayValue = false
    //       }
    //     } else {
    //       // No function, try the plain field, maybe it's a URL
    //       displayValue = fieldValue
    //     }
    //     break
    //   case 'switch':
    //   case 'toggle':
    //   case 'flag':
    //   case 'boolean':
    //     displayValue = fieldValue ? 'Yes' : 'No'
    //     break
    //   case 'tags':
    //     displayValue = fieldValue.join(', ')
    //     break
    //   default:
    //     displayValue = fieldValue
    // }
    // return displayValue
  }

  // ----------
  // Control ==
  // ----------

  control (context) {
    // Populate control with field properties
    let control = {
      name: this.name,
      model: this.model,
      type: this.type,
      id: this.id,
      label: this.label,
      placeholder: this.placeholder,
      hint: this.hint,
      disabled: this.readOnly,
      error: this.invalid,
      errorMessages: this.errors,
      display: () => this.display(),
      get: () => this.model.get(this.name),
      set: (value) => this.model.set(this.name, value)
    }
    // Add type-specific options
    switch (control.type) {
      case 'range':
        control.min = this.options.min || 0
        control.max = this.options.max || 100
        break
    }
    // Context overrides
    if (context) {
      control.label = context.label || control.label
      control.hint ||= context.hint
      control.display ||= context.display
      control.disabled ||= context.disabled
      control.autofocus ||= context.autofocus
    }
    // Filter out unset valued
    Object.keys(control).forEach((k) => !(control[k] || control[k] === false) && delete control[k])

    return control
  }
}

class AutocompleteField extends BField {

  control (context) {
    const control = super.control(context)
    return {
      ...control,
      query: this.options.query,
      items: [],
      itemText: this.options.text,
      itemValue: this.options.value,
      static: this.options.static || false
    }
  }
}

class CdnField extends BField {

  control (context) {
    // console.log('control', this.name, context)
    const control = super.control(context)
    return {
      ...control,
      localCropping: this.options.cropping === 'local'
    }
  }
}
class TagsField extends BField {

  control (context) {
    // console.log('control', this.name, context)
    const control = super.control(context)
    return {
      ...control,
      items: []
    }
  }
}

class SelectField extends BField {

  control (context) {
    // console.log('control', this.name, context)
    const control = super.control(context)
    return {
      ...control,
      items: context.options || this.options || []
    }
  }
}

class TextareaField extends BField {

  control (context) {
    // console.log('control', this.name, context)
    const control = super.control(context)
    return {
      ...control,
      rows: context.rows || this.options.rows || 3,
      autoGrow: context.autogrow || this.type === 'autogrow'
    }
  }
}
