export const alerts = {
  state: {
    notices: [],
    errors: [],
  },
  getters: {
    pageError: state => state.errors.length > 0
  },
  mutations: {
    resetAlerts(state) {
      state.notices = []
      state.errors = []
    },
    addNotice(state, payload) {
      state.notices.push(payload)
    },
    addError(state, payload) {
      state.errors.push(payload)
    }
  },
  actions: {
    processError({ commit, dispatch }, error) {
      // First, let's go to the top of the page
      window.scrollTo(0, 0)
      // Handle internal validation errors
      if (!error.response) {
        console.error('Processing Error', error)
        if (error.error && error.error.message) {
          commit('addError', {
            message: error.error.message
          })
        // } else {
        //   for (const singleError of Object.entries(error.errors)) {
        //     commit('addError', {
        //       title: singleError,
        //       message: error.errors[singleError][0]
        //     })
        //   }
        }
        return
      }
      // API Response errors
      const statusResponse = error.response.response || error.response
      const statusCode = statusResponse.status
      // console.error('HTTP Error', error)
      // console.error('HTTP Response', statusResponse)
      // console.error('HTTP Code', statusCode)
      switch (statusCode) {

        // Server Error
        case 500:
          console.error('500 Fatal Error', statusResponse)
          commit('addError', {
            title: statusResponse.data.error,
            message: statusResponse.data.exception
          })
          break;

          // Not Authorized / Session Expired
        case 401:
          console.error('401 Unauthorized', statusResponse)
          dispatch("push", { name: "login" }, { root: true });
          break;

          // Forbidden
        case 403:
          console.error('403 Forbidden', statusResponse)
          commit('addError', {
            title: 'Not Allowed',
            message: 'The requested action could not be completed.'
          })
          break;

          // Not Found
        case 404:
          console.error('404 Not Found', statusResponse)
          commit('addError', {
            title: 'Not Found',
            message: 'The requested item could not be found.',
            type: 'warning'
          })
          break;

        // Validation Error
        case 400:
        case 422:
          console.error('400 Validation Error', statusResponse)
          // Reset errors so people don't get confused
          commit('resetAlerts')
          // Show error - Disabled because the form itself seems
          // to be doing a good job for now...
          // commit('addError', {
          //   title: 'Form Error',
          //   message: 'The request could not be completed.'
          // })
      }
      throw error
    }
  }
}