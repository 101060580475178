// Models
import WikiBinary from '@/models/WikiBinary'
import WikiPage from '@/models/WikiPage'
import WikiSearch from '@/models/WikiSearch'
import WikiUploader from '@/models/WikiUploader'
// import store from '@/store'

export default {
  namespaced: true,
  state: {
    allowEdits: false,
    editMode: false,
    wikiPage: new WikiPage(),
    wikiSearch: new WikiSearch(),
    uploader: new WikiUploader(),
    wikiIndex: []
  },
  getters: {
    allowEdits() {
      return true
      // if (state.wikiPage.owners && state.wikiPage.owners.length) {
      //   const userEmail = store.getters['currentUser/getJWTPayload'].email
      //   const isAdmin = store.getters['currentUser/isAdmin']
      //   return isAdmin || state.wikiPage.owners.includes(userEmail)
      // } else {
      //   return true
      // }
    },
    wikiBinaries(state) {
      return state.wikiPage.binaries.getModels()
    },
    wikiImages(state) {
      return state.wikiPage.images.getModels()
    },
    loading(state) {
      return state.wikiPage.loading || state.wikiSearch.loading
    }
  },
  mutations: {
    NEW_PAGE(state, {wiki, path}) {
      state.wikiPage = new WikiPage({wiki, path})
    },
    VIEW_PAGE(state) {
      state.editMode = false
    },
    EDIT_PAGE(state) {
      state.editMode = true
    },
    RESET_PAGE(state) {
      if (state.wikiPage.changed()) {
        state.wikiPage.reset(state.wikiPage.changed())
      }
    },
    RESET_SEARCH(state) {
      state.wikiSearch = new WikiSearch()
    }
  },
  actions: {
    async loadIndex({ state }) {
      state.wikiIndex = await state.wikiPage.index()
    },
    async loadPage({ commit, dispatch, state }, { wiki, path }) {
      state.wikiPage = new WikiPage()
      // Open pages in view mode
      commit('VIEW_PAGE')
      // Clean up path
      if (path.endsWith('/')) { path = `${path}index` }
      // Initialize wiki page
      state.wikiPage.set({ wiki, id: path })
      // Fetch
      await state.wikiPage.fetch()
        .catch((error) => {
          const status = error.error.response.status
          if (status == 404) {
            commit('NEW_PAGE', {wiki, path})
            commit('EDIT_PAGE')
          } else {
            dispatch('processError', error, { root: true })
          }
        })
        // Make sure we have a publishing status
      //   if (!state.wikiPage.get('status')) {
      //     state.wikiPage.set('status', 'Published')
      //   }
      // } catch (error) {
      //   dispatch('processError', { error }, { root: true })
    },
    loadSearch({ commit, state }, query) {
      commit('RESET_SEARCH')
      state.wikiSearch.set({ query })
      state.wikiSearch.fetch()
    },
    viewPage({ commit }) {
      commit('VIEW_PAGE')
    },
    editPage({ commit }) {
      commit('EDIT_PAGE')
    },
    resetPage({ commit }) {
      commit('RESET_PAGE')
    },
    async savePage({ dispatch, state }) {
      try {
        await state.wikiPage.save()
        dispatch('viewPage')
      } catch (error) {
        dispatch('processError', { error }, { root: true })
      }
    },
    async uploadFiles({ dispatch, state }) {
      const wiki = state.wikiPage.wiki
      const path = state.wikiPage.id
      // Loop through all the attached files
      for (let index in state.uploader.uploadFiles) {
        const file = state.uploader.uploadFiles[index]
        // Select the collection (images or binaries)
        const collection = file.type.startsWith('image/') ? state.wikiPage.images : state.wikiPage.binaries
        // Post binary
        let newBinary = new WikiBinary({ wiki, path, binary: file }, collection)
        await newBinary.save().catch(error => {
          dispatch('processError', { error }, { root: true })
        })
      }
      // Reset uploaded files
      state.uploader.set('uploadFiles', [])
    },
    async deleteImage({ dispatch }, image) {
      try {
        // Delete image
        await image.delete()
      } catch (error) {
        dispatch('processError', { error }, { root: true })
      }
    },
    async deleteBinary({ dispatch }, binary) {
      try {
        // Delete binary
        await binary.delete()
      } catch (error) {
        dispatch('processError', { error }, { root: true })
      }
    }
  }
}