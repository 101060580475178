// ----------------
// Bifrost Model ==
// ----------------

import { Model } from 'vue-mc'
import BCollection from './BCollection'
import BField from './BField'

import Vue from 'vue'
import Cookies from 'js-cookie'
import { values, pickBy, isEmpty } from 'lodash'

export default class BModel extends Model {

  // ----------
  // Options --
  // ----------

  bOptions() {
    return {}
  }

  options() {
    // Set timestamp
    this._created = Date.now()
    // Build fields
    this.initFields()
    const manifest = this._manifest
    for (let name in manifest) {
      this.addField(name, BField.make(name, manifest[name], this))
    }
    // Return options
    return {
      patch: true,
      validateRecursively: false,
      ...this.bOptions()
    }
  }

  // -------------------
  // Field Management ==
  // -------------------

  initFields () {
    this._fields = {}
  }

  addField (name, field) {
    this._fields[name] = field
  }

  getField (name) {
    return this._fields[name]
  }

  get fields () {
    return values(this._fields)
  }

  // -----------
  // Manifest --
  // -----------

  get _manifest() {
    return []
  }

  defaults() {
    const defaults = this.fields.reduce(
      (defaults, field) => {
        defaults[field.name] = field.default
        return defaults
      }, {})
    return defaults
  }

  validation() {
    const validations = this.fields.reduce(
      (validations, field) => {
        if (field.validation !== null) validations[field.name] = field.validation
        return validations
      }, {})
    return validations
  }

  mutations() {
    const mutations = this.fields.reduce(
      (mutations, field) => {
        if (field.mutation !== null) mutations[field.name] = field.mutation
        return mutations
      }, {})
    return mutations
  }

  changed() {
    const changed = super.changed()
    // Return false values
    if (changed === false) { return false }
    // Filter out any BCollections
    return changed.filter(field => !(this[field] instanceof BCollection))
  }

  reset() {
    this.changed().map(
      field => super.reset(field)
    )
  }

  get pristine() {
    let changed = this.changed()
    return (changed === false || changed.length == 0)
  }

  get modified() {
    return !(this.pristine)
  }
  
  // -------------
  // Properties ==
  // -------------

  isNew() {
    const identifier = this.getOption('identifier')
    const id_value = this.get(identifier)
    return isEmpty(id_value)
  }

  get created() {
    return this._created
  }

  get key() {
    return `${this.remoteModel}-${this.created}`
  }

  get isValid() {
    return isEmpty(this.errors)
  }

  // ---------
  // Policy ==
  // ---------

  get currentUser() {
    return Vue.prototype.$user
  }

  get policy() {
    return {}
  }

  allow(action) {
    return this.policy[action] || false
  }

  // ---------
  // Client --
  // ---------

  assign(parameters) {
    // Get parameters from JSON root if defined
    // (it would be the same as remoteModel)
    let attributes = parameters[this.remoteModel] ? parameters[this.remoteModel] : parameters
    // Extract relationships
    attributes = this.extractRelated(attributes)
    // Assign attributes
    super.assign(attributes)
  }

  extractRelated(attributes) {
    // Get our models
    const models = pickBy(this._manifest, f => f.type == 'model')
    for (let field in models) {
      // Save field attributes
      const modelAttributes = attributes[field] || {}
      // Create model for field
      attributes[field] = models[field].default
      // Set model attributes
      attributes[field].set(modelAttributes)
    }
    // Get our collections
    const collections = pickBy(this._manifest, f => f.type == 'collection')
    for (let field in collections) {
      // Save collection models
      const collectionModels = attributes[field] || []
      // console.log('collections', attributes, field, collectionModels, collections[field].default)
      // Create collection for field
      attributes[field] = collections[field].default
      // Set collection attributes
      attributes[field].set(this.parentInfo)
      // Set collection models
      attributes[field].add(collectionModels)
    }
    return attributes
  }

  // Build default routes from a given remotePath
  routes() {
    const identifier = this.getOption('identifier')
    return {
      fetch: `${this.remotePath}/{${identifier}}`,
      create: `${this.remotePath}`,
      save: `${this.remotePath}/{${identifier}}`,
      delete: `${this.remotePath}/{${identifier}}`
    }
  }

  // Add JSON headers + authentication to requests
  getDefaultHeaders() {
    const token = Cookies.get(process.env.VUE_APP_COOKIE_NAME)
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    return headers
  }

  // Use standard REST methods
  getDefaultMethods() {
    return {
      fetch:  'GET',
      save:   'POST',
      update: 'PUT',
      create: 'POST',
      patch:  'PATCH',
      delete: 'DELETE',
    }
  }

  // Name of the Rails model, defaults
  // to vue-mc name
  get remoteModel() {
    return this.getOption('remoteModel')
  }

  // Base REST path for the Rails model
  get remotePath() {
    return this.getOption('remotePath')
  }

  // If a remote model is defined, put all the values
  // in an object named after the remote model, in order
  // to follow Rails conventions
  remoteFieldName(name) {
    if (this.remoteModel) {
      return `${this.remoteModel}[${name}]`
    } else {
      return name
    }
  }

  // Convert data to a Rails format, and handle uploads
  getSaveData() {
    const flattenObject = (obj, prefix = '') => {
      if (!obj) return null
      else return Object.keys(obj).map((key) => {
        // Build path prefix
        const pathPrefix = prefix.length ? prefix + '.' : ''
        // Check what kind of value we're working with
        const isObject = (typeof obj[key]) === 'object'
        const isFile = obj[key] instanceof File
        if (isObject && !isFile) {
          // Call self on nested objects
          flattenObject(obj[key], pathPrefix + key)
        } else {
          // Save current path and value for non-objects
          const fieldName = `${this.remoteModel}[${(pathPrefix + key).replaceAll('.', '][')}]`
          formData.append(fieldName, obj[key])
        }
      })
    }

    // let modelData = this.flattenPaths(this.serialize())
    let formData = new FormData()
    flattenObject(this.serialize())
    // Add original data
    // for (let field in modelData) {
    //   const fieldName = `${this.remoteModel}[${field.replaceAll('.', '][')}]`
    //   formData.append(fieldName, modelData[field])
    // }
    return formData
  }

  serialize(options = {}) {
    let serialized = {}
    // Get default save data
    const attributes = super.getSaveData()
    // Add id if we have one
    if (options.includeId && this.identifier()) {
      attributes.id = this.identifier()
    } else {
      delete(attributes.id)
    }
    // Process all fields
    for (let name in attributes) {
      // Get the field
      const field = this.getField(name)
      // Skip fields that aren't ours
      if (isEmpty(field)) { continue }

      // Save the value
      const value = attributes[name]
      if (value instanceof BModel) {
        // Call serializer on BModels
        serialized[`${name}_attributes`] = value.serialize({ includeId:true })
      // Do not process collections, these will have their own manager
      } else if (!(value instanceof BCollection)) {
        // Only include non-null values because FormData
        // can't hack anything other than a string or a file.
        // Since it's not possible for a user to set a null
        // value, it's safe to assume the original value is null
        // and should remain unchanged.
        const isRemote = !field.local
        const isNotNull = field.value !== null
        const isNotEmptyFile = field.type != 'file' || field.value != ''
        if (isRemote && isNotNull && isNotEmptyFile) {
          // Save regular values
          serialized[field.name] = value
        }
      }
    }
    return serialized
  }

  flattenPaths(data) {
    const flattenObject = (obj, prefix = '') => {
      if (!obj) return null
      else return Object.keys(obj).reduce((memo, key) => {
        // Build path prefix
        const pathPrefix = prefix.length ? prefix + '.' : ''
        // Check what kind of value we're working with
        const isObject = (typeof obj[key]) === 'object'
        const isFile = obj[key] instanceof File
        const isArray = obj[key].constructor === Array
        if (isObject && !isFile && !isArray) {
          // Call self on nested objects
          Object.assign(memo, flattenObject(obj[key], pathPrefix + key))
        } else {
          // Save current path and value for non-objects
          memo[pathPrefix + key] = obj[key]
        }
        return memo
      }, {})
    }
    return flattenObject(data)
  }

  // ----------
  // Utility ==
  // ----------

  get newModel() {
    const identifier = this.identifier()
    return !identifier
  }

  get modelId() {
    const identifier = this.identifier()
    const remoteModel = this.remoteModel
    if (identifier && remoteModel) {
      return `${remoteModel}-${identifier}`
    } else {
      return `${new Date().valueOf()}.${new Date().getUTCMilliseconds()}`
    }
  }

  // ----------
  // Uploads --
  // ----------

  // Add an upload to the model
  addUpload(field, file) {
    // Update our field
    this.set(field, file)
  }
}
