import Publicist from '@/models/Publicist'
import PublicistList from '@/models/PublicistList'
import OppList from '@/models/OppList'

export default {
  namespaced: true,
  state: {
    // Search
    query: new PublicistList([], {}, { listing: 'publicists' }),
    // Individual Publicist
    publicist: new Publicist(),
    opps: new OppList(),
    // Publicist Listing
    publicists: new PublicistList([], {}, { listing: 'publicists' }),
  },
  getters: {
    // UI
    loading(state) {
      return state.publicist.loading
        || state.publicists.loading
        || state.opps.loading
        || state.query.loading
    },
    show(state) {
      return !state.publicist.fatal
    }
  },
  mutations: {
    // Init
    NEW_PUBLICIST: (state, defaults) => state.publicist = new Publicist(defaults),
    SET_PUBLICIST: (state, publicist) => state.publicist = publicist,
    RESET_PUBLICIST (state) {
      if (state.publicist.modified) {
        state.publicist.reset(state.publicist.changed())
      }
    },
    // Search
    NEW_QUERY: (state, defaults) => { state.query = new PublicistList([], {}, defaults) }
  },
  actions: {

    // Fetch query from server
    fetchQuery({ state }) {
      // console.log('publicists/fetchQuery')
      return state.query.fetch()
    },

    // Fetch the publicists listing
    fetchPublicists ({ dispatch, state }, page = 1) {
      // console.log('publicists/fetchPublicists')
      state.publicists.set('current_page', page)
      dispatch('fetchCollection', state.publicists, { root: true })
    },

    // Fetch an individual publicist
    fetchPublicist ({ commit, dispatch, state }, slug) {
      // console.log('publicists/fetchPublicist', slug)
      commit('NEW_PUBLICIST', { slug })
      dispatch('fetchModel', state.publicist, { root: true })
    },

    // Fetch the opps for a given Publicist (paginated)
    fetchOpps({ dispatch, state }, { slug, page = 1, query = '' }) {
      // console.log('publicists/fetchArticles', slug, page, query)
      // Get the article listing for this slug
      state.opps.set({
        listing: `from-${slug}`,
        current_page: page,
        query
      })
      // Fetch opportunities
      dispatch('fetchCollection', state.opps, { root: true })
    },

    // Save the current Publicist
    savePublicist ({ dispatch, state }) {
      // console.log('publicists/savePublicist')
      return dispatch('saveModel', state.publicist, { root: true })
    }
  }
}
