// ------------------
// Publicist Model ==
// ------------------

import BModel from '@/models/base/BModel'

import { email } from 'vue-mc/validation'

export default class Publicist extends BModel {

  bOptions() {
    return {
      remoteModel: 'publicist',
      remotePath: '/api/publicists',
      identifier: 'slug'
    }
  }

  get _manifest() {
    return {
      first_name: {
        label: 'First Name'
      },
      last_name: {
        label: 'Last Name'
      },
      email: {
        label: 'Email',
        type: 'email',
        validation: email
      },
      website: {
        label: 'Website',
        type: 'url'
      },
      location: {
        label: 'Location'
      },
      organization: {
        label: 'Organization'
      },
      contact_notes: {
        label: 'Contact Notes',
        type: 'markdown'
      },
      newsletter: {
        label: 'Subscribe to publicists newsletter',
        type: 'switch'
      },
      // Read Only
      name:              String,
      initials:          String,
      slug:              String,
      latest_item:       Object,
      created_at:        Date,
      updated_at:        Date
    }
  }
}
