<template>

  <!-- Button - Tooltip -->
  <v-tooltip v-if="help" bottom content-class="jexy-tooltip">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        active-class="jexy-active"
        :disabled="disabled"
        :color="buttonColor"
        :block="block"
        :icon="iconButton"
        :rounded="rounded"
        :text="text"
        :loading="processing"
        :x-small="xSmall"
        :to="to"
        :class="buttonClass"
        @click.prevent="handleClick"
      >
        <j-icon
          v-if="icon"
          :icon="icon"
          class="mr-1"
        />
        {{ label }}
      </v-btn>
    </template>
    <span>{{ help }}</span>
  </v-tooltip>

  <!-- Button - No Tooltip -->
  <v-btn
    v-else
    active-class="jexy-active"
    :disabled="disabled"
    :color="buttonColor"
    :block="block"
    :icon="iconButton"
    :rounded="rounded"
    :text="text"
    :loading="processing"
    :x-small="xSmall"
    :to="to"
    :class="buttonClass"
    @click.prevent="handleClick"
  >
    <j-icon
      v-if="icon"
      :icon="icon"
      class="mr-1"
    />
    {{ label }}
  </v-btn>
</template>

<script>

export default {
  name: 'JexyButton',
  // -------------
  // Properties ==
  // -------------
  props: {
    type: {
      type: String,
      default: 'primary' // primary / secondary / danger / option / confirm / header
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: null
    },
    buttonClass: {
      type: String,
      default: null
    },
    help: {
      type: String,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    buttonColor () {
      // Return explicit color if given
      if (this.color) {
        return this.color
      }
      // Otherwise, use default for type
      switch (this.type) {
        case 'option':
          return null
        case 'danger':
        case 'confirm':
          return 'error'
        default:
          return this.type
      }
    },
    text () {
      return ['option','confirm'].includes(this.type)
    },
    iconButton () {
      return (this.type === 'icon')
    },
    rounded () {
      return (this.type === 'round' || this.type === 'header')
    },
    xSmall () {
      return (this.type === 'header')
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    handleClick () {
      this.$emit('action')
    }
  }
}
</script>

<style lang="scss">

  .jexy-tooltip {
    @extend %j-label;
  }

</style>
