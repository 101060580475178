<template>

  <!-- Menu Item -->
  <v-list-item :to="to" @click="$emit('click')">
    <v-list-item-icon>
      <j-icon :icon="icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="tool-label">
        {{ label }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'JexyMenuItem',
  props: {
    icon: {
      type: [String, Array],
      default: null
    },
    label: {
      type: String,
      default: 'default'
    },
    to: {
      type: [Object, String],
      default: null
    }
  }
}
</script>
