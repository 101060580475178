// -----------------
// Opp List Model ==
// -----------------

import BCollection from './base/BCollection'
import Opp from './Opp'

export default class OppListing extends BCollection {

  bOptions() {
    return {
      model: Opp,
      remoteModel: 'opps'
    }
  }

  routes() {
    return {
      fetch: '/api/opps/?l={listing}&q={query}&p={current_page}&r={current_rows}'
    }
  }

  defaults() {
    return {
      ...this.paginationFields
    }
  }
}
