import store from '@/store'

export default class User {

  get slug() {
    return this.payload.slug
  }

  get name() {
    return this.payload.name
  }

  get isAdmin() {
    return this.payload.admin
  }

  isEditor(sectionSlug=false) {
    const editor = this.groups.includes('Editor')
    const sectionEditor = !!sectionSlug && this.payload.sections.includes(sectionSlug)
    return editor || sectionEditor
  }

  get groups() {
    return this.payload.groups || []
  }

  get initials() {
    return this.payload.initials
  }

  get payload() {
    return store.state.auth.payload
  }

  get profile() {
    return store.state.auth.profile
  }

  login(jwt) {
    return store.dispatch('login', jwt)
  }
  
  loginReturn() {
    store.dispatch('loginReturn')
  }

  logout() {
    store.dispatch('logout')
  }

  refresh() {
    store.state.auth.profile.fetch()
  }
}
