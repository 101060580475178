// ---------------------
// Contact List Model ==
// ---------------------

import BCollection from './base/BCollection'
import Contact from './Contact'

export default class ContactListing extends BCollection {

  bOptions() {
    return {
      model: Contact,
      remoteModel: 'contacts'
    }
  }

  routes() {
    return {
      fetch: '/api/{contact_type}/?l={listing}&q={query}&p={current_page}&r={current_rows}'
    }
  }

  defaults() {
    return {
      ...this.paginationFields,
      contact_type: 'staff'
    }
  }
}
