<template>
  <div
    id="app"
    class="container"
  >
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: '...',
      titleTemplate: '%s :: Ink 19'
    }
  }
</script>

<style>
  @import './assets/styles/kexy.css';
</style>