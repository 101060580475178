// --------------------------
// Announcement List Model ==
// --------------------------

import BCollection from './base/BCollection'
import Announcement from './Announcement'

export default class AnnouncementList extends BCollection {

  bOptions() {
    return {
      model: Announcement,
      remoteModel: 'announcements'
    }
  }

  routes() {
    return {
      fetch: '/api/announcements/?l={listing}&q={query}&p={current_page}&r={current_rows}'
    }
  }

  defaults() {
    return {
      ...this.paginationFields
    }
  }
}
