import { get } from 'lodash'

export default {
  inject: ['modelRef'],
  props: {
    name: String,
    type: String,
    label: String,
    hint: String,
    placeholder: String,
    autocomplete: String,
    originalSrc: String,
    append: [String, Array],
    buttons: Boolean,
    hideLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object
    },
    cropSettings: {
      type: Object
    },
    rows: String,
    options: [Object, Array],
    showBlank: Boolean
  },
  computed: {
    control () {
      // Get model
      let model = {}
      if (typeof this.modelRef == 'string') {
        if (this.modelRef.endsWith(']')) {
          const matched = this.modelRef.match(/^(.*)\[(.*)\]$/)
          const listingName = matched[1]
          const tag19 = matched[2]
          const listing = get(this.$store.state, listingName)
          model = listing.find({ tag19 })
        } else {
          model = get(this.$store.state, this.modelRef)
        }
      } else {
        model = this.modelRef
      }
      // Get field
      const field = model.getField(this.name)
      // Return control
      return field.control(this.$props)
    },
    appendIcon () {
      if (!this.append) { return null }

      if (typeof this.append == 'string') {
        return ['fa', this.append]
      } else {
        return this.append
      }
    }
  }
}