<template>
  <v-chip
    v-if="labelText || $slots.default"
    :color="labelColor"
    :small="hasSection || small"
    :outlined="!solid || hasSection"
    :label="hasSection || squared "
    class="label-text"
    @click="$emit('click')"
  >
    <slot>
      <j-icon
        v-if="icon"
        :icon="icon"
        class="mr-1"
      />
      <span
        class="jexy-label-title"
        v-html="labelText"
      />
      <span v-if="subtitle">&nbsp;/&nbsp;</span>
      <span
        v-if="subtitle"
        class="jexy-label-subtitle"
        v-html="subtitle"
      />
    </slot>
  </v-chip>
</template>

<script>
import { truncate } from 'lodash'

export default {
  name: 'JexyLabel',
  // -------------
  // Properties ==
  // -------------
  props: {
    title: {
      type: [String, Number],
      default: null
    },
    subtitle: {
      type: [String, Number],
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    squared: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    solid: {
      type: Boolean,
      default: false
    },
    // Special Cases
    section: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    hasSection () {
      return !!this.section
    },
    labelSmall () {
      if (this.hasSection) {
        return true
      } else {
        return this.small
      }
    },
    labelText () {
      if (this.hasSection) {
        return truncate(this.section.name, {length: 15})
      } else {
        return this.title
      }
    },
    labelColor () {
      if (this.hasSection) {
        return this.section.highlight_color || 'primary'
      } else if (this.color) {
        return this.color
      } else if (this.small) {
        return ''
      } else {
        return 'primary'
      }
    }
  }
}
</script>

<style lang="scss">

.label-text {
  @extend %j-label;
} 

</style>
