import store from '@/store'

const beforeEnter = async (to, _from, next) => {
  // Initialize authentication
  store.dispatch('authInit')
  // What's the situation?
  const isLoggedIn = store.getters.isLoggedIn
  const isNotExpiring = !store.getters['isExpiring']
  // Continue or send to login
  if (isLoggedIn && isNotExpiring) {
    next()
  } else {
    // Save the page for return after login
    store.dispatch('loginReturn', to)
    // Send to login
    next('/login')
  }
}

export default { beforeEnter }
