<template>
  <div
    v-if="pageLoaded"
    class="jexy-content"
    :class="{ fill }"
  >
    <!-- Header Row -->
    <v-row class="page-header">
      <v-col cols="9" class="py-0">

        <!-- Icon -->
        <j-icon
          v-if="icon"
          :icon="icon"
          :color="iconColor"
          size="lg"
          class="mr-1 mb-2"
        />

        <!-- Title Prefix -->
        <j-label
          v-if="titlePrefix && !loading"
          :title="titlePrefix"
          :color="titlePrefixColor"
          solid
          class="mr-2 mt-n4 header-label"
        />

        <!-- Title -->
        <h2
          :style="loading ? 'color: #999;' : ''"
          class="main-heading mb-1"
          v-html="pageTitle || '&nbsp;'"
        />

        <!-- Subtitle / Label -->
        <j-label
          v-if="subtitle && !loading"
          :title="subtitle"
          :color="subtitleColor"
          small squared
          class="header-label ml-2 mt-n3"
        />

      </v-col>

      <!-- Loading -->
      <v-col
        v-if="loading"
        class="d-flex align-center justify-end py-0"
      >
        <j-icon
          v-if="loading"
          icon="star"
          color="#999"
          spin
          size="lg"
        />
      </v-col>

      <!-- Actions -->
      <v-col
        v-else-if="$slots.actions"
        class="d-flex align-center justify-end py-0"
        align-self="center"
      >
        <slot name="actions" />
      </v-col>
    </v-row>

    <!-- Content Row -->
    <v-row :class="`page-content ${contentRowClass}`">

      <!-- Left -->
      <v-col
        v-if="showLeft"
        :cols="leftCols"
        class="left-column"
      >
        <slot name="left"></slot>
      </v-col>

      <!-- Page Content -->
      <!-- <v-col
        v-if="showDefault"
        class="content-column"
      > -->
        <slot />
      <!-- </v-col> -->

      <!-- Right -->
      <v-col
        v-if="showRight"
        :cols="rightCols"
        class="right-column"
      >
        <slot name="right"></slot>
      </v-col>

    </v-row>

    <slot name="dialogs"></slot>
  </div>
</template>

<script>

import { isNull, isEmpty, truncate } from 'lodash'

export default {
  name: 'JexyContent',
  props: {
    title: {
      type: String,
      default: ''
    },
    titlePrefix: {
      type: String,
      default: ''
    },
    titlePrefixColor: {
      type: String,
      default: 'primary'
    },
    subtitle: {
      type: String,
      default: ''
    },
    subtitleColor: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    },
    contentRowClass: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    leftCols: {
      type: [String, Number],
      default: 6
    }
  },
  computed: {
    pageLoaded () {
      // TODO - this needs to be smarter, eg show on 
      // validation errors but not 500s
      return true // !this.$store.getters['pageError']
    },
    pageTitle () {
      const truncation = this.titlePrefix ? 35 : 40
      const title = truncate(this.title, { length: truncation })
      return title
    },
    showLeft () {
      return this.leftCols > 0 && this.isPresent(this.$slots.left)
    },
    showRight () {
      return this.rightCols > 0 && this.isPresent(this.$slots.right)
    },
    showDefault () {
      return this.isPresent(this.$slots.default)
    },
    rightCols () {
      return 12 - this.leftCols
    }
  },
  methods: {
    isPresent(slot) {
      return !(isNull(slot) || isEmpty(slot))
    }
  }
}

</script>

<style lang="scss">

.jexy-content {

  // Vertical Fill
  &.fill {
    .page-content {
      height: calc(100vh - 160px);
      .left-column,
      .content-column,
      .right-column {
        height: 100%;
        overflow-y: scroll;
      }
    }
  }

  .page-header {
    @extend %j-background-medium;
    border-radius: 6px 6px 0 0;

    .main-heading {
      @extend %j-heading-main;
      display: inline-block;
    }

    .v-chip.v-chip--outlined.v-chip.v-chip.header-label {
      background-color: white !important;
    }
  }

  .page-content {
    @extend %j-background-page;
    border-radius: 0 0 6px 6px;
  }
}

</style>
