// import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary:   '#a03d41',
        secondary: '#CCCCCC',
        accent:    '#F28D4C',
        error:     '#F02c2c',
        info:      '#a03d41',
      },
    },
  }
})
