import Announcement from '@/models/Announcement'
import AnnouncementList from '@/models/AnnouncementList'

export default {
  namespaced: true,
  state: {
    // Individual Announcement
    announcement: new Announcement(),
    // Listing
    announcements: new AnnouncementList({}, [], { current_rows: 0 })
  },
  getters: {
    // UI
    loading(state) {
      return state.announcement.loading
        || state.announcements.loading
    },
    // Policy
    canEdit() {
      return this.$user.isEditor()
    }
  },
  mutations: {
    // Init
    NEW_ANNOUNCEMENT: (state) => state.announcement = new Announcement(),
  },
  actions: {

    // Fetch announcements
    fetchAnnouncements({ dispatch, state }, listing = 'index') {
      // console.log('announcements/fetchAnnouncements', listing)
      state.announcements.set({ listing })
      dispatch('fetchCollection', state.announcements, { root: true })
    },

    // Fetch announcement
    fetchAnnouncement({ commit, dispatch, state }, tag19) {
      // console.log('announcement/fetchAnnouncement', tag19)
      commit('NEW_ANNOUNCEMENT')
      // Fetch given announcement
      if (tag19) {
        state.announcement.set('tag19', tag19)
        dispatch('fetchModel', state.announcement, { root: true })
      }
    },

    // Save the current announcement
    saveAnnouncement({ dispatch, state }) {
      // console.log('announcements/saveAnnouncement')
      return dispatch('saveModel', state.announcement, { root: true })
    },

    // Delete the current announcement
    deleteAnnouncement({ dispatch, state }) {
      // console.log('announcements/deleteAnnouncement')
      return dispatch('deleteModel', state.announcement, { root: true })
    }
  }
}
