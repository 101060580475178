<template>
  <v-sheet
    class="jexy-table"
  >
    <!-- Heading -->
    <j-heading
      v-if="heading"
      :heading="heading"
      :subhead="subhead"
      :count="showCount && listing.length"
    >
      <template #actions>
        <slot name="actions" />
      </template>
    </j-heading>

    <v-divider />

    <!-- Table -->
    <v-simple-table
      dense
      class="jexy-table mb-4"
    >
      <thead v-if="$slots.header">
        <!-- Header -->
        <slot name="header" />
      </thead>
      <tbody v-if="listing.length">
        <!-- Listing -->
        <slot name="listing" />
      </tbody>

      <!-- No Items -->
      <caption
        v-else
        class="text-left py-2"
      >
        <!-- Loading -->
        <p
          v-if="listing.loading"
          class="my-0"
        >
          Loading {{ items }}...
        </p>

        <!-- Custom Empty Listing -->
        <template v-else-if="$slots.empty">
          <slot name="empty" />
        </template>

        <!-- Default empy listing -->
        <p
          v-else
          class="my-0"
        >
          No {{ items }}.
        </p>
      </caption>

      <tfoot v-if="$slots.footer">
        <slot name="footer">
          Footer
        </slot>
      </tfoot>
    </v-simple-table>

    <!-- Pagination -->
    <v-pagination
      v-if="hasPagination"
      v-model="currentPage"
      :total-visible="shortPagination ? 5 : 9"
      :length="pages"
      prev-icon="fa-angle-left"
      next-icon="fa-angle-right"
    />
  </v-sheet>
</template>

<script>

export default {
  name: 'JexyTable',
  // -------------
  // Properties ==
  // -------------
  props: {
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    items: {
      type: String,
      default: 'items'
    },
    listing: {
      type: Object,
      default: null
    },
    showCount: {
      type: [Number, Boolean],
      default: false
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    shortPagination: {
      type: Boolean,
      default: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    currentPage: {
      get: function () {
        return (parseInt(this.listing.get('current_page')) || parseInt(this.$route.query.p) || 1)
      },
      set: function (page) {
        this.$emit('paginate', page)
      }
    },
    hasPagination() {
      return this.showPagination && this.listing.get('pages') > 1
    },
    pages() {
      return this.listing.get('pages')
    },
  },
  // --------
  // Watch ==
  // --------
  watch: {
    '$route' () {
      this.currentPage = this.$route.query.p
    }
  }
}
</script>

<style lang="scss">

.jexy-table {
  width: 100%;
  caption-side: bottom;

  caption {
    @extend %j-body-small;
  }

  tr {
    th {
      @extend %j-label-large;
      border-top: none;
      padding: 0 4px !important;
    }
    td {
      @extend %j-body;
      padding: 0 4px !important;
    }
  }
  tr > th:first-child, tr > td:first-child {
    padding-left: 0 !important;
  }
  tr > th:last-child, tr > td:last-child {
    padding-right: 0 !important;
  }
} 

</style>
