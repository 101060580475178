import BModel from '@/models/base/BModel'

export default class WikiSearch extends BModel {
  _name () {
    return 'wiki_search'
  }

  manifest () {
    return {
      query: {
        label: 'Query'
      },
      count: {
        default: 0
      },
      hits: {
        default: []
      }
    }
  }

  routes () {
    return {
      fetch: '/wiki_source/search/{query}'
    }
  }
}
