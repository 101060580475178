<template>
  <v-badge
    v-if="count"
    color="primary"
    inline
  >
    <template #badge>
      {{ count }}
    </template>
  </v-badge>
</template>

<script>

export default {
  name: 'JexyCounter',
  props: {
    count: {
      type: [String, Number],
      default: null
    }
  }
}
</script>
