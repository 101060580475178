import BModel from '@/models/base/BModel'
// import WikiBinaryList from './WikiBinaryList'
import { isEmpty } from 'lodash'
import {
  required
} from 'vue-mc/validation'

export default class WikiPage extends BModel {

  bOptions() {
    return {
      identifier: 'path',
      remoteModel: 'wiki_page',
      patch: false
    }
  }

  get _manifest () {
    return {
      title: {
        label: 'Title',
        validation: required,
        local: true
      },
      body: {
        label: 'Body',
        type: 'textarea',
        validation: required
      },
      owners: {
        label: 'Owners',
        type: 'tags'
      },
      status: {
        label: 'Status',
        type: 'select',
        options: ['Draft', 'Published'],
        default: 'Published',
        local: true
      },
      toc: {
        label: 'Table of Contents',
        type: 'switch',
        default: false,
        local: true
      },
      access: {
        label: 'Access',
        default: 'staff',
        local: true
      },
      // Read Only
      wiki: String,
      redirect: String,
      // binaries: new WikiBinaryList,
      // images: new WikiBinaryList,
      author_name: String,
      author_email: String,
      last_update: Date,
      navigation: Object,
      path: String
    }
  }

  // -------------
  // Wiki Index --
  // -------------

  index() {
    const method = 'GET'
    const url = `/wiki_source/index`
    const headers = this.getDefaultHeaders()

    return this.getRequest({ method, url, headers })
      .send()
      .then(this.parseIndex)
  }

  parseIndex(response) {
    const index = response.response.data
    return index
  }
  
  // -----------
  // Metadata --
  // -----------

  serialize() {
    // Get everything
    const metadata = this.attributes
    // Extract the body
    const body = metadata.body
    // Remove unwanted metadata
    const skipFields = [
      'body',
      'id',
      'wiki',
      'path',
      'author_name',
      'author_email',
      'last_update',
      'navigation',
      'binaries',
      'images'
    ]
    for (let idx in skipFields) delete metadata[skipFields[idx]]

    return { body, metadata }
  }

  isNew () {
    return isEmpty(this.get('id'))
  }

  changed() {
    let originalChanged = super.changed()
    if (originalChanged) {
      const filterFields = [this.getOption('identifier'), 'images']
      const filteredChanged = originalChanged.filter(field => !filterFields.includes(field))
      return filteredChanged
    } else {
      return originalChanged
    }
  }

  assign(attributes) {
    // const images = attributes.images instanceof WikiBinaryList ? attributes.images.models : attributes.images
    // attributes.images = new WikiBinaryList(images, {}, {
    //   page_id: attributes.id
    // })
    // const binaries = attributes.binaries instanceof WikiBinaryList ? attributes.binaries.models : attributes.binaries
    // attributes.binaries = new WikiBinaryList(binaries, {}, {
    //   page_id: attributes.id
    // })
    super.assign(attributes)
  }

  routes () {
    return {
      fetch: '/kanikapila/page/{wiki}/{id}',
      create: '/kanikapila/page/{wiki}/{path}',
      save: '/kanikapila/page/{wiki}/{id}'
    }
  }
}
