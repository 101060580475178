<template>
  <v-card class="jexy-comment my-4">
    <v-card-title v-if="title">
      <span
        class="jexy-title"
        v-html="title"
      />
    </v-card-title>

    <j-card-text class="body pb-0">
      <j-markdown
        :source="body"
        class="jexy-body"
      />
      <v-divider />
    </j-card-text>

    <v-card-actions class="pl-0">
      <v-list-item class="grow">
        <v-list-item-avatar>
          <j-avatar
            :source="avatar"
            :alt="initials"
            size="35"
            class="jexy-avatar"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="jexy-author">
            {{ author }}
          </v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <span class="jexy-timestamp subheading">
            <j-icon
              :icon="icon"
              class="jexy-icon mr-1"
            />
            {{ timestamp | longDate }}
          </span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>


<script>

export default {
  name: 'JexyComment',
  // -------------
  // Properties ==
  // -------------
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    initials: {
      type: String,
      default: ''
    },
    author: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    timestamp: {
      type: [Date, String],
      default: ''
    }
  }
}
</script>

<style lang="scss">

.jexy-comment {

  // .jexy-title {}
  // .jexy-avatar {}
  // .jexy-icon {}

  .jexy-body {
    @extend %j-body;
  }

  .jexy-author {
    @extend %j-label-large;
  }

  .jexy-timestamp {
    @extend %j-label;
  }
}

</style>