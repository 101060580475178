<template>
  <v-alert
    :type="type || 'info'"
    :color="type == 'info' ? '#666' : null"
    :dismissible="dismissible"
    border="left"
    outlined
    dense
  >

    <!-- Alert Title -->
    <h3
      v-if="title"
      class="alert-title"
      v-html="title"
    />

    <!-- Message Property -->
    <j-markdown
      v-if="message"
      class="alert-message ma-0"
      :source="message"
    />

    <!-- Message Slot -->
    <div v-else class="alert-message ma-0">
      <slot />
    </div>

  </v-alert>
</template>

<script>

export default {
  name: 'JexyAlert',
  props: {
    type: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.v-alert {
  width: 100%;
  .alert-title {
    @extend %j-headline;
  }
  .alert-message {
    @extend %j-body;
  }
}
</style>