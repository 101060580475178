import BModel from '@/models/base/BModel'
import { required } from 'vue-mc/validation'

export default class Comment extends BModel {

  bOptions() {
    return {
      remoteModel: 'comment'
    }
  }

  get _manifest() {
    return {
      body: {
        label: 'Comment',
        validation: required,
        type: 'markdown'
      },
      visibility: {
        label: 'Visibility',
        validation: required,
        type: 'select',
        default: 'Staff',
        options: [
          {
            text:  'Author', 
            value: 'Author'
          }, {
            text:  'Author + Editors', 
            value: 'Editors'
          }, {
            text:  'Staff', 
            value: 'Staff'
          }
        ]
      },
      // Read Only
      tag19:           String,
      author:          String,
      author_avatar:   String,
      author_initials: String,
      created_at:      Date,
      updated_at:      Date,
      parent_type:     String,
      parent_id:       Number
    }
  }

  routes() {
    return {
      fetch:  '/api/comments/{tag19}',
      create: '/api/{parent_type}/{parent_id}/comments',
      save:   '/api/comments/{tag19}',
      delete: '/api/comments/{tag19}'
    }
  }
}
