import BModel from '@/models/base/BModel'
import {
  required,
  email,
  // same
} from 'vue-mc/validation'

export default class Auth extends BModel {

  bOptions() {
    return {
      remoteModel: 'auth',
      identifier: 'slug'
    }
  }

  get _manifest() {
    return {
      email: {
        label: 'Email',
        validation: required.and(email)
      },
      password: {
        label: 'Password',
        type: 'password'
      },
      slug: String,
      token: String
    }
  }

  alias() {
    const method = this.getOption('methods.create');
    const route = this.getRoute('alias');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.onFetchSuccess)
  }

  reset() {
    const method = this.getOption('methods.create');
    const route = this.getRoute('reset');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const data = { email: this.get('email') }
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers, data })
      .send()
  }

  validateReset() {
    const method = this.getOption('methods.fetch');
    const route = this.getRoute('validateReset');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.onFetchSuccess)
  }

  routes() {
    return {
      create: '/api/login',
      alias: '/api/alias/{slug}',
      reset: '/api/reset',
      validateReset: '/api/reset/{token}'
    }
  }
}
