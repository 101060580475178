// -------------------
// Opp Source Model ==
// -------------------

import BModel from '@/models/base/BModel'

export default class OppSource extends BModel {

  bOptions () {
    return {
      remoteModel: 'opp',
      identifier: 'tag19'
    }
  }

  get _manifest () {
    return {
      // Read Only
      tag19:        String,
      source:       String,
    }
  }

  routes () {
    return {
      fetch: '/api/opps/{tag19}/source',
    }
  }

  fetch () {
    // Set up
    const method = this.getOption('methods.fetch');
    const route = this.getRoute('fetch');
    const params = this.getRouteParameters();
    const url = this.getURL(route, params);
    const headers = this.getDefaultHeaders()
    // Execute
    return this.createRequest({ method, url, headers })
      .send()
      .then(this.saveSource)
  }

  saveSource(response) {
    this.set('source', response.response.data)
  }
}
