<template>
  <v-tab
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <j-icon
      v-if="icon"
      :icon="icon"
      class="mr-2"
    />
    <span class="tab-text">
      <slot />
    </span>
    <j-counter
      v-if="count"
      :count="count"
    />
  </v-tab>
</template>

<script>

// Vuex

export default {
  name: 'JexyTab',
  props: {
    icon: {
      type: String,
      default: null
    },
    count: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style lang="scss">

.jexy-tab {
  .tab-text {
    @extend %j-label-large;
  }
}
</style>
