<template>
  <v-card
    class="jexy-tile"
    :min-height="minHeight"
    :color="backgroundColor"
    outlined
  >
    <j-card-text class="pa-3">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row no-gutters>
          <v-col
            v-if="hasGraphic"
            cols="3"
            class="tile-graphic mr-2"
          >
            <!-- Badge -->
            <slot name="graphic" />
            <j-avatar
              v-if="useAvatar"
              :source="badgeUrl"
              :alt="badgeAlt"
            />
            <j-graphic
              v-else-if="badgeUrl"
              :src="badgeUrl"
              :alt="badgeAlt"
              aspect-ratio="1"
              width="100%"
            />
          </v-col>
          <v-col>
            <!-- Actions -->
            <slot name="actions" />

            <!-- Kicker -->
            <h2
              v-if="kicker"
              class="tile-kicker mb-1"
              :style="kickerColor ? `color: ${kickerColor};` : ''"
            >
              <j-icon
                v-if="kickerIcon"
                :icon="kickerIcon"
                size="xs"
                class="mr-1"
              />
              <span v-html="kicker" />
            </h2>
            <h3
            />

            <!-- Title -->
            <h2 class="tile-headline mb-2">
              <j-icon
                v-if="headlineIcon"
                :icon="headlineIcon"
                size="xs"
                class="mr-1"
              />
              <span v-html="headline" />
            </h2>

            <!-- Description -->
            <j-markdown
              v-if="description"
              :source="description"
              class="tile-description mb-1"
            />

            <!-- Tags -->
            <template v-if="tileTags">
              <j-label
                v-for="(tag, index) in tileTags"
                :key="`${tag}-${index}`"
                :title="tag"
                small
                class="tile-tags mr-1 mb-2"
              />
              <j-label
                v-if="notShownCount || !condenseTags"
                :title="condenseTags ? `+${notShownCount} more` : '&laquo;'"
                small
                class="tile-tags mr-1 mb-2"
                @click="toggleTagExpansion"
              />
            </template>

            <!-- Body -->
            <slot />

            <!-- Link -->
            <template v-if="showLink">
              <p class="tile-link mb-0"><slot name="link"/></p>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </j-card-text>
    <v-card-actions
      v-if="$slots.footer"
      class="tile-actions px-3 py-0"
    >
      <!-- Footer -->
      <slot name="footer" />
    </v-card-actions>
  </v-card>
</template>

<script>

import { isEmpty } from 'lodash'

export default {
  name: 'Tile',
  // -------------
  // Properties ==
  // -------------
  props: {
    useAvatar: {
      type: Boolean,
      default: false
    },
    badgeUrl: {
      type: String,
      default: ''
    },
    badgeAlt: {
      type: String,
      default: ''
    },
    kicker: {
      type: String,
      default: ''
    },
    kickerColor: {
      type: String,
      default: ''
    },
    kickerIcon: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    headlineIcon: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    tags: {
      type: [Array, String],
      default: null
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      condenseTags: true
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    tileTags () {
      if (isEmpty(this.tags)) return []

      if (this.condenseTags && this.tags.length > 4) {
        return this.tags.slice(0, 3)
      } else {
        return this.tags
      }
    },
    showLink () {
      return !isEmpty(this.$slots.link)
    },
    notShownCount () {
      if (this.condenseTags) {
        return isEmpty(this.tags) || this.tags.length <= 4 ? 0 : this.tags.length - 3
      } else {
        return 0
      }
    },
    hasGraphic () {
      return (this.badgeUrl || !isEmpty(this.$slots.graphic))
    },
    minHeight () {
      if (this.size || this.size == 'sm') {
        return 100
      } else {
        return null
      }
    }
  },
  methods: {
    toggleTagExpansion () {
      this.condenseTags = !this.condenseTags
    }
  }
}
</script>

<style lang="scss">

.jexy-tile {

  .tile-actions {
    @extend %j-background-light;
  }

  .tile-kicker {
    @extend %j-kicker;
  }

  .tile-headline {
    @extend %j-headline;
  }

  .tile-description {
    @extend %j-description;
  }

  .tile-tag {
    @extend %j-label;
  }

  .tile-link {
    @extend %j-label;
  }
}

</style>
