// -------------------
// Image List Model ==
// -------------------

import BCollection from './base/BCollection'
import Image from './Image'

export default class ImageList extends BCollection {

  bOptions() {
    return {
      model: Image,
      remoteModel: 'images'
    }
  }

  routes() {
    return {
      fetch: '/api/{parent_type}/{parent_id}/images'
    }
  }
}