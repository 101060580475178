import router from '@/router'

export const route = {
  actions: {
    // Send to a given route
    push({ state }, route) {
      state // No-op
      router.push(route)
    }
  }
}
