<template>
  <component
    :is="inputComponent"
    v-bind="$props"
    class="j-input"
    @change="$emit('change')"
  />
</template>

<script>

import Control from './controls/Mixin'

export default {
  name: "JInput",
  mixins: [ Control ],
  // -------------
  // Components ==
  // -------------
  components: {
    'JexyAutocomplete': () => import('./controls/Autocomplete'),
    'JexyCdn':          () => import('./controls/Cdn'),
    'JexyCheckbox':     () => import('./controls/Checkbox'),
    'JexyColor':        () => import('./controls/Color'),
    'JexyCheckboxes':   () => import('./controls/Checkboxes'),
    'JexyDate':         () => import('./controls/Date'),
    'JexyDatetime':     () => import('./controls/Datetime'),
    'JexyFile':         () => import('./controls/File'),
    'JexyRange':        () => import('./controls/Range'),
    'JexySelect':       () => import('./controls/Select'),
    'JexyString':       () => import('./controls/String'),
    'JexyTextarea':     () => import('./controls/Textarea'),
    'JexyTags':         () => import('./controls/Tags'),
    'JexyToggle':       () => import('./controls/Toggle')
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    inputComponent() {
      switch (this.control.type) {
        case 'autocomplete':
          return 'JexyAutocomplete'
        case 'boolean':
          return 'JexyCheckbox'
        case 'cdn':
          return 'JexyCdn'
        case 'checkboxes':
          return 'JexyCheckboxes'
        case 'color':
          return 'JexyColor'
        case 'date':
          return 'JexyDate'
        case 'datetime':
          return 'JexyDatetime'
        case 'file':
          return 'JexyFile'
        case 'range':
          return 'JexyRange'
        case 'select':
          return 'JexySelect'
        case 'tags':
          return 'JexyTags'
        case 'autogrow':
        case 'markdown':
        case 'textarea':
          return 'JexyTextarea'
        case 'flag':
        case 'switch':
        case 'toggle':
          return 'JexyToggle'
        // Default to String
        default:
          return 'JexyString'
      }
    }
  }
}
</script>

<style lang="scss">

.j-input {

  label {
    font-family: $heading-font !important;
    font-weight: bold;
  }

  input, textarea, select, .v-select__slot {
    font-family: $body-font !important;
  }
} 

</style>
