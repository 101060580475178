// ----------------
// Mailbox Model ==
// ----------------

import BModel from '@/models/base/BModel'
import MailboxLabelList from '@/models/MailboxLabelList'
import MailboxLabel from '@/models/MailboxLabel'

export default class Mailbox extends BModel {

  bOptions() {
    return {
      remoteModel: 'mailbox',
      identifier: 'owner_slug'
    }
  }

  get _manifest() {
    return {
      processed_opp_label: {
        label: 'Mark processed items with',
        type: 'select',
        options: () => this.get('account_labels')
      },
      error_opp_label: {
        label: 'Mark error items with',
        type: 'select',
        options: () => this.get('account_labels')
      },
      // Associations
      labels: {
        type: 'collection',
        default: new MailboxLabelList
      },
      // Read Only
      account_labels: Array,
      authorized: Boolean,
      configured: Boolean,
      expired: Boolean,
      owner_slug: String,
      creator: String,
      created_at: Date,
      updated_at: Date
    };
  }

  get pristine() {
    const labels = this.get('labels')
    if (!labels) { return true }

    return labels.getModels().every((label) => label.pristine) && super.pristine
  }

  // ------------
  // Parenting ==
  // ------------

  serialize() {
    const labelSerializer = (label) => ({
      id: label.id || '',
      label: label.label,
      section_slug: label.section_slug,
      mailbox_id: label.mailbox_id,
      _destroy: label._destroy,
    })
    const labels = this.get('labels').getModels().map(labelSerializer)
    const result = super.serialize()
    result.mailbox_labels =  labels
    console.log('labels', result)
    return result
  }

  oauth2() {
    const method = this.getOption('methods.fetch')
    const route = this.getRoute('oauth2')
    const params = this.getRouteParameters()
    const url = this.getURL(route, params)
    const headers = this.getDefaultHeaders()
    return this.createRequest({ method, url, headers })
      .send()
      .then(({ response }) => { return response.data })
  }

  authorize(code) {
    const method = this.getOption('methods.create')
    const route = this.getRoute('authorize')
    const params = this.getRouteParameters()
    const url = this.getURL(route, params)
    const headers = {
      ...this.getDefaultHeaders(),
      'X-Requested-With': 'XMLHttpRequest',
    }
    const data = { code }
    return this.createRequest({ method, url, headers, data })
      .send()
      .then(this.onFetchSuccess)
  }

  addLabel() {
    this.get('labels').add(new MailboxLabel({ mailbox_id: this.id }))
  }

  removeLabel(label) {
    this.get('labels').remove(label)
  }

  routes() {
    return {
      fetch:  '/api/staff/{owner_slug}/mailbox',
      save:   '/api/staff/{owner_slug}/mailbox',
      delete: '/api/staff/{owner_slug}/mailbox',
      oauth2: '/api/oauth2',
      authorize: '/api/staff/{owner_slug}/mailbox/tokens'
    }
  }
}
