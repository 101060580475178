<template>
  <v-avatar
    color="primary"
    :size="size"
    class="avatar-source"
    :class="{ 'float-left': left, 'float-right': right }"
  >
    <v-img
      v-if="avatarSrc"
      :src="avatarSrc"
    />
    <span
      v-else-if="alt"
      class="initials white--text title"
    >{{ alt }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: 'JexyAvatar',
  // -------------
  // Properties ==
  // -------------
  props: {
    source: {
      type: [Object, String],
      default: null
    },
    size: {
      type: [Number, String],
      default: 75
    },
    alt: {
      type: String,
      default: ''
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    avatarSrc() {
      if (typeof this.source == 'string') {
        return this.source
      } else if (typeof this.source == 'object' && this.source) {
        return this.source.get('badge_src')
      } else {
        // wtf
        return ''
      }
    }
  }
}
</script>

<style lang="scss">

.initials {
  font-weight: bold !important;
}

/* .avatar-source {
  clip-path: url(#superellipse);
} */

</style>