// --------------
// Draft Model ==
// --------------

import BModel from '@/models/base/BModel'
import Article from './Article'
import Opp from './Opp'
import Section from './Section'

// import { isNotMatch } from '@/models/base/BValidation'
import { required, url, email } from 'vue-mc/validation'
import { isEmpty, compact } from 'lodash'
import moment from 'moment'

// Testing helper
var debug = false

export default class Draft extends BModel {

  bOptions() {
    return {
      remoteModel: 'article',
      identifier: 'tag19'
    }
  }

  get _manifest() {
    return {
      // Section
      section_slug: {
        label: 'Section',
        type: 'select',
        // validation: required.or(isNotMatch(this.get('format'))),
        options: [
          // News articles are the only type of article currently
          // being directly selected from a drop-down, other types
          // are having the section_slug set externally.
          { value: 'band-news', text: 'Band News' },
          { value: 'ink-links', text: 'Ink Links' },
          { value: 'know-your-rights', text: 'Know Your Rights' },
          { value: 'label-news', text: 'Label News' },
          { value: 'milestones', text: 'Milestones' },
          { value: 'music-news', text: 'Music News' },
          { value: 'print-news', text: 'Print News' },
          { value: 'quotes', text: 'Quotes' },
          { value: 'screen-news', text: 'Screen News' },
          { value: 'tour-news', text: 'Tour News' }
        ]
      },
      // Format
      format: {
        label: 'Format',
        type: 'select',
        default: debug ? 'interviews' : '',
        validation: required,
        options: [
          { value: 'event-reviews', text: 'Event Reviews' },
          { value: 'features', text: 'Features' },
          { value: 'gear-reviews', text: 'Gear Reviews' },
          { value: 'interviews', text: 'Interviews' },
          { value: 'music-reviews', text: 'Music Reviews' },
          { value: 'print-reviews', text: 'Print Reviews' },
          { value: 'screen-reviews', text: 'Screen Reviews' },
          { value: 'news', text: 'News' }
        ]
      },
      // Standard Header
      title: {
        label: 'Title',
        default: debug ? 'Article Title' : '',
        validation: required
      },
      kicker: {
        label: 'Kicker (optional)',
        default: debug ? 'Kicker' : ''
      },
      subtitle: {
        label: 'Subtitle (optional)',
        default: debug ? 'Subtitle' : ''
      },
      released_on: {
        label: 'Release / Embargo Date',
        type: 'date'
      },
      // Event Review Header
      support: {
        label: 'Supporting Act(s) (optional)',
        default: debug ? 'Support, Additional Support' : ''
      },
      place: {
        label: 'Place (city, state, country)',
        default: debug ? 'Place' : ''
      },
      date: {
        label: 'Date',
        default: debug ? '1/15/2020' : ''
      },
      // Gear Review Header
      category: {
        label: 'Category',
        default: debug ? 'Category' : ''
      },
      manufacturer: {
        label: 'Manufacturer',
        default: debug ? 'Manufacturer' : ''
      },
      // Music Review | Staff Pick Header
      album: {
        label: 'Album',
        default: debug ? 'Album' : ''
      },
      label: {
        label: 'Label',
        default: debug ? 'Label' : ''
      },
      // Print Review Header
      print_author: {
        label: 'Author(s)',
        default: debug ? 'First Author, Second Author' : ''
      },
      publisher: {
        label: 'Publisher (omit if self-published)',
        default: debug ? 'Publisher' : ''
      },
      // Screen Review Header
      director: {
        label: 'Director',
        default: debug ? 'Big Name Director' : ''
      },
      cast: {
        label: 'Cast',
        default: debug ? 'Actor One, Acress Two' : ''
      },
      studio: {
        label: 'Studio (omit if self-released)',
        default: debug ? 'Studio' : ''
      },
      // Staff Pick Fields
      release_date: {
        label: 'Release Date',
        type: 'date',
        default: debug ? '2020-11-06' : ''
      },
      playlist: {
        label: 'Link (URL only)',
        type: 'url',
        default: debug ? 'https://joejacktalcum.bandcamp.com/album/joe-jack-talcum-sings-railroad-bill-and-other-songs' : ''
      },
      // Body / Description / Byline / URL
      body: {
        label: 'Body',
        type: 'textarea',
        default: debug ? 'Body\n  \nline2    with       extra spaces. \n\nq: Is this a question?\n\na: here is an answer' : ''
      },
      description: {
        label: 'Description',
        type: 'markdown',
        validation: required,
        default: debug ? 'Description' : ''
      },
      byline: {
        label: 'Byline',
        validation: required
      },
      url: {
        label: 'Related Site(s)',
        type: 'url',
        validation: url.or(isEmpty),
        default: debug ? 'https://www.something.com/' : ''
      },
      // Additional Metadata
      tags: {
        label: 'Tags',
        type: 'tags',
        default: debug ? ['tag1', 'tag2', 'tag3'] : ''
      },
      contact: {
        label: 'Contact Email',
        type: 'email',
        validation: email.or(isEmpty),
        default: debug ? 'publicist@label.com' : ''
      },
      // Uploads
      badge_src: {
        label: 'Feature Image',
        type: 'cdn'
      },
      // Associations
      source: Object,
      // Article for posting
      article: {
        type: 'model',
        default: new Article
      }
    }
  }

  get hasSource () {
    const source = this.get('source')
    return (source instanceof Opp || source instanceof Section)
  }

  get sourceType () {
    const source = this.get('source')
    if (source instanceof Opp) {
      return 'assignment'
    } else if (source instanceof Section) {
      return 'section'
    } else {
      return 'none'
    }
  }

  get composedBody () {
    // Build header
    let header = []
    let middle = []
    let footer = []
    let endBlock = ''
    let byline = ''
    // Byline and end block
    switch (this.get('format')) {
      // Features
      case 'event-reviews':
      case 'features':
      case 'interviews':
        if (this.get('title') && this.get('byline')) middle.push(`-bb by ${this.get('byline')}`)
        endBlock = '&end;'
        break;
      // Reviews + others
      default:
        byline = (this.get('format') === 'news') ? 'Ink 19 News' : this.get('byline')
        if (this.get('title') && this.get('byline')) footer.push(`-wb ${byline}`)
        break;
    }
    // Header
    var location
    switch (this.get('format')) {
      // Event Reviews
      case 'event-reviews':
        // Headline Act
        if (this.get('title')) header.push(`-lh ${this.get('title')}`)
        // Supporting Acts
        if (this.get('support')) header.push(`-ls with ${this.get('support')}`)
        // Location
        location = [this.get('place'), this.get('date')].filter(n => n).join(' &bull; ')
        if (location) header.push(`-lp ${location}`)
        break
      case 'gear-reviews':
        // Product
        if (this.get('title')) header.push(`-gp ${this.get('title')}`)
        // Category
        if (this.get('category')) header.push(`-gt ${this.get('category')}`)
        // Manufacturer
        if (this.get('manufacturer')) header.push(`-gm ${this.get('manufacturer')}`)
        break;
      case 'music-reviews':
        // Artist
        if (this.get('title')) header.push(`-ma ${this.get('title')}`)
        // Album
        if (this.get('album')) header.push(`-mt ${this.get('album')}`)
        // Label
        if (this.get('label')) header.push(`-ml ${this.get('label')}`)
        break;
      case 'print-reviews':
        // Title
        if (this.get('title')) header.push(`-pt ${this.get('title')}`)
        // Author
        if (this.get('print_author')) header.push(`-pa ${this.get('print_author')}`)
        // Publisher
        if (this.get('publisher')) header.push(`-pp ${this.get('publisher')}`)
        break;
      case 'screen-reviews':
        // Title
        if (this.get('title')) header.push(`-st ${this.get('title')}`)
        // Director
        if (this.get('director')) header.push(`-sc directed by ${this.get('director')}`)
        // Cast
        if (this.get('cast')) header.push(`-sc starring ${this.get('cast')}`)
        // Studio
        if (this.get('studio')) header.push(`-ss ${this.get('studio')}`)
        break;
      case 'staff-picks':
        // Artist
        if (this.get('title')) header.push(`-xa ${this.get('title')}`)
        // Album
        if (this.get('album')) header.push(`-xt ${this.get('album')}`)
        // Label
        if (this.get('label')) header.push(`-xl ${this.get('label')}`)
        break;
      default:
        // Kicker
        if (this.get('kicker')) header.push(`-fs ${this.get('kicker')}`)
        // Title
        if (this.get('title')) header.push(`-ft ${this.get('title')}`)
        // Subtitle
        if (this.get('subtitle')) header.push(`-fs ${this.get('subtitle')}`)
        break
    }
    // Middle
    let dirtyStrings = this.get('body').split(/\n\s*\n/)
    const lineCount = dirtyStrings.length
    for (let lineIndex in dirtyStrings) {
      let bodyLine = dirtyStrings[lineIndex]
      // Remove extra spaces
      bodyLine = bodyLine.replace(/\s+/g, ' ')
      // Add end block to the last line if not blank
      if (lineIndex + 1 == lineCount && bodyLine) bodyLine = `${bodyLine} ${endBlock}`
      // Set body styles
      const prefix = bodyLine.slice(0,2).toLowerCase()
      if (prefix == 'q:') {
        middle.push(`-bq ${bodyLine.slice(3)}`)
      } else if (prefix == 'a:') {
        middle.push(`-ba ${bodyLine.slice(3)}`)
      } else {
        if (lineIndex == 0) {
          if (bodyLine) middle.push(`-bf ${bodyLine}`)
        } else {
          if (bodyLine) middle.push(`-bm ${bodyLine}`)
        }
      }
    }
    // Add URLs
    if (this.get('url')) middle.push(`-wd ${this.get('url')}`)
    // Footer
    if (this.get('format') == 'staff-picks') {
      // Divider
      footer.push(`---`)
      // Artist
      if (this.get('title')) footer.push(`-ma ${this.get('title')}`)
      // Album
      if (this.get('album')) footer.push(`-mt ${this.get('album')}`)
      // Label
      if (this.get('label')) footer.push(`-ml ${this.get('label')}`)
      // RIYL
      if (this.get('tags')) footer.push(`-bf RIYL: ${this.get('tags').join(', ')}`)
      // Release Date
      if (this.get('release_date')) {
        const releaseDate = moment(this.get('release_date')).format('MMMM D, YYYY')
        footer.push(`-bf Released: ${releaseDate}`)
      }
      // URL
      if (this.get('playlist')) footer.push(`-wd ${this.get('playlist')}`)
      // Tags
      if (this.get('tags')) {
        let hashtags = [this.get('title'), this.get('label'), ...this.get('tags')]
        hashtags = compact(hashtags).map((hashtag) => `#${hashtag.replaceAll(' ','')}`)
        footer.push(`-ml ${hashtags.join(' ')}`)
      }
    }
    // Compose body and return
    return [
      ...header,
      ...middle,
      ...footer
    ].join("\n\n")
  }

  get composedDescription () {
    let description = this.get('description')
    switch (this.get('format')) {
      case 'music-reviews':
        description = `_${this.get('album')}_`
        if (this.get('label')) {
          description = `${description} (${this.get('label')})`
        }
        description = `${description}. Review by **${this.get('byline')}**.`
        break
      case 'staff-picks':
        description = `_${this.get('album')}_`
        if (this.get('label')) {
          description = `${description} (${this.get('label')})`
        }
        description = `${description}. Recommended by **${this.get('byline')}**.`
        break
    }
    return description
  }

  get composedTags () {
    let tags = []
    // Start with byline
    if (this.get('format') !== 'news') {
      tags.push(this.get('byline'))
    }
    // Add from headers according to article type
    switch (this.get('format')) {
      case 'event-reviews':
        tags.push(this.get('title'))
        tags.push(this.get('support'))
        tags.push(this.get('place'))
        tags.push(this.get('date'))
        break
      case 'gear-reviews':
        tags.push(this.get('title'))
        tags.push(this.get('category'))
        tags.push(this.get('manufacturer'))
        break
      case 'interviews':
        tags.push(this.get('title'))
        break
      case 'music-reviews':
      case 'staff-picks':
        tags.push(this.get('title'))
        tags.push(this.get('album'))
        tags.push(this.get('label'))
        break
      case 'print-reviews':
        tags.push(this.get('title'))
        tags.push(this.get('print_author'))
        tags.push(this.get('publisher'))
        break
      case 'screen-reviews':
        tags.push(this.get('title'))
        tags.push(this.get('director'))
        tags.push(this.get('cast'))
        tags.push(this.get('studio'))
        break
    }
    // Add any other tags
    tags.push(...this.get('tags'))
    // Cleanup time
    return tags
  }

  get articleAttributes () {
    return {
      title: this.get('title'),
      body: this.composedBody,
      description: this.composedDescription,
      byline: this.get('byline'),
      section_slug: this.get('section_slug') || this.get('format'),
      opp_tag19: (this.sourceType == 'assignment') ? this.source.tag19 : null,
      badge_src: this.get('badge_src'),
      contact: this.get('contact'),
      released_on: this.get('released_on'),
      tags: this.composedTags,
      stage: 'draft'
    }
  }

  get articlePreview () {
    return new Article(this.articleAttributes)
  }

  save () {
    // First try basic validation
    return this.validate().then(() => {
      // Conditional validation
      switch (this.get('format')) {
        case 'music-reviews':
          this.setAttributeErrors('description', false);
          if (!this.get('album')) this.setAttributeErrors('album', 'Required');
          break
        case 'event-reviews':
          if (!this.get('place')) this.setAttributeErrors('place', 'Required');
          if (!this.get('date')) this.setAttributeErrors('date', 'Required');
          break
        case 'news':
          if (!this.get('section_slug')) this.setAttributeErrors('section_slug', 'Required');
          break
      }
      // Save if we don't have errors
      if (isEmpty(this.errors)) {
        this.article.set(this.articleAttributes)
        this.saving = true
        return this.article.save().then(() => this.saving = false)
      } else {
        return Promise.reject()
      }
    })
  }

  get cdnMetadata() {
    return {
      // Currently only news items have attached
      // images from a draft
      folder: 'news',
      context: {
        tag19: this.get('tag19')
      },
      tags: [
        'news',
        this.get('title'),
        moment().format('Y-M')
      ]
    }
  }
}
